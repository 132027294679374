<template>
  <column2 class="bg-grayCol2">
  </column2>
</template>

<script>
import Column2 from '@/components/lib/Layout/Column2';
import BookingEngineMixin from '@/mixins/BookingEngineMixin';

export default {
  name: 'EmptyColumn2',
  components: { Column2 },

  mixins: [BookingEngineMixin],
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.left-1100 {
  left: 1100px;
  max-width: clamp(250px, calc(100vw - 1100px),700px)
}
</style>
