<template>
  <div v-click-outside="onClickOutside">
    <resize-sensor @resize="onresize"></resize-sensor>
    <div :style="[colorStyles, { '--months-margin': monthsMarginComputed }]" class="wrapper">
      <template v-if="!multiLine">
        <div class="button__wrapper">
          <div v-show="canScrollLeft"
               :class="{ 'button--image': imageButtonPrev }"
               :style="imageButtonStyle(imageButtonPrev)"
               class="button button--previous"
               @click="scrollLeft"
          >
            <template v-if="imageButtonPrev === null">
              &lt;
            </template>
          </div>
        </div>
        <div class="button__wrapper button__wrapper--right">
          <div v-show="canScrollRight"
               :class="{ 'button--image': imageButtonNext }"
               :style="imageButtonStyle(imageButtonNext)"
               class="button button--next"
               @click="scrollRight"
          >
            <template v-if="imageButtonNext === null">
              &gt;
            </template>
          </div>
        </div>
      </template>
      <div ref="container" :style="{ '--visible-months': responsiveMonths }" class="calendar__container">
        <div v-if="loaded"
             :class="[
               {'calendar--border': dayBorder === false},
               {'calendar--multi-line': multiLine},
               {'calendar--start-date-selected': !isEmpty(selection.startDate)},
               {'calendar--end-date-selected': !isEmpty(selection.endDate)}]"
             class="calendar"
        >
          <weekdays :weekdays="weekdays"
                    :first-day-of-week="firstDayOfWeek"
          />
          <month v-for="month in visibleMonths"
                 :key="month.toISOString()"
                 :label="formatMonth(month)"
                 :weekdays="weekdays"
                 :first-day-of-week="firstDayOfWeek"
                 :first-day-of-month="month"
                 :selected-dates="selectedDates"
                 :selection-active="selectionActive"
                 :min-date="minSelectableDate"
                 :max-date="maxSelectableDate"
                 :date-colors="dateColors"
                 :ari="ari"
                 :availability="availability"
                 @clickpast="cancelSelection"
                 @selectstartdate="onSelectDate($event, month, 'start')"
                 @selectenddate="onSelectDate($event, month, 'end')"
                 @hoverdate="onHoverDate($event, month)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import fecha from 'fecha';
import ResizeSensor from 'vue-resize-sensor';
import isNil from 'lodash/isNil';
import Month from './Month';
import Weekdays from './Weekdays';
import baseUrl from '@/lib/urls';
import { isEmpty } from 'lodash/lang';
import vClickOutside from 'v-click-outside';
import { map } from 'lodash/collection';

export default {
  name: 'AvailabilityCalendar',
  components: { Month, ResizeSensor, Weekdays },

  directives: {
    clickOutside: vClickOutside.directive,
  },

  props: {
    locale: {
      type: String,
      default: 'de',
    },
    months: {
      type: Number,
      default: 1,
    },
    maxMonths: {
      type: Number,
      default: 36,
    },
    unavailableColor: {
      type: String,
      default: '#f2eeee', /* f2eeee eccccd */
    },
    unavailableTextColor: {
      type: String,
      default: '#c8d6e0', /* c8d6e0 a1abb3 */
    },
    borderColor: {
      type: String,
      default: null,
    },
    dayBorder: {
      type: Boolean,
      default: true,
    },
    imageButtonNext: {
      type: String,
      default: null,
    },
    imageButtonPrev: {
      type: String,
      default: null,
    },
    monthsMargin: {
      type: Number,
      default: 70,
    },
    multiLine: {
      type: Boolean,
      default: true,
    },
    dateColors: {
      type: Object,
      default: () => ({}),
    },
    hotel: {
      type: String,
      default: null,
    },
    roomType: {
      type: String,
      default: null,
    },
    roomNumber: {
      type: String,
      default: null,
    },
    allowDisabled: { // allow selection of disabled dates
      type: Boolean,
      default: false,
    },
    startDate: {
      type: String,
      default: null,
    },
    endDate: {
      type: String,
      default: null,
    },
    ari: {
      type: Object,
      default: null,
    },
    availability: {
      type: Object,
      default: null,
    },
  },

  data: function () {
    return {
      today: new Date(),
      mounted: false,
      datesLoaded: false,
      disabledDates: [],
      arrivalOnlyDates: [],
      departureOnlyDates: [],
      noArrivalDates: [],
      visibleMonth: 0,
      responsiveMonths: this.months,
      selection: {
        startDate: null,
        endDate: null,
        hoverDate: null,
      },
      minDate: null,
      weekdayDateMap: {
        Mon: new Date('2020-01-06'),
        Tue: new Date('2020-01-07'),
        Wed: new Date('2020-01-08'),
        Thu: new Date('2020-01-09'),
        Fri: new Date('2020-01-10'),
        Sat: new Date('2020-01-11'),
        Sun: new Date('2020-01-12'),
      },
    };
  },

  i18n: {
    de: {
      firstDayOfWeek: 'mo',
    },
    en: {
      firstDayOfWeek: 'su',
    },
  },

  computed: {
    roomTypeIds() {
      if (isNil(this.hotelData)) return [];
      return map(this.hotelData.roomTypes, 'id');
    },
    hotelData: function () {
      return this.$store.getters.hotel;
    },
    minSelectableDate() {
      if (isNil(this.selection.startDate) || !isEmpty(this.selection.endDate)) return this.minDate;
      else return fecha.format(fecha.parse(this.selection.startDate, 'YYYYMMDD'), 'YYYY-MM-DD');
    },
    maxSelectableDate() {
      if (isNil(this.selection.startDate) || !isEmpty(this.selection.endDate)) return this.maxDate;
      else {
        const startDate = fecha.parse(this.selection.startDate, 'YYYYMMDD');
        let endDate = fecha.parse(this.maxDate, 'YYYY-MM-DD');
        const MAX_BOOKABLE_DAYS = 365; // this is the maximum number of days bookable through the IBE
        let maxLos = MAX_BOOKABLE_DAYS;
        if (this.$store.getters.hotel.details.onlineBookingSettings.extendedAriValidation === true && !isNil(this.ari) && !isNil(this.ari.byDate)) {
          const ari = this.ari.byDate[this.selection.startDate];
          if (!isNil(ari) && !isNil(ari.fplos)) {
            maxLos = Math.max(maxLos, (ari.fplos.lastIndexOf(false) + 1));
            if (maxLos === 8) maxLos = MAX_BOOKABLE_DAYS;
            endDate = this.addDays(startDate, maxLos);
          }
        }

        for (let i = 1; i <= maxLos; i++) {
          if (!isNil(this.disabledDates) && this.disabledDates.includes(fecha.format(this.addDays(startDate, i), 'YYYY-MM-DD'))) {
            endDate = this.addDays(startDate, i);
            break;
          }
        }

        return fecha.format(endDate, 'YYYY-MM-DD');
      }
    },
    maxDate() {
      const hotelSettings = this.$store.getters.hotel;
      if (!isNil(hotelSettings) && !isNil(hotelSettings.details) && !isNil(hotelSettings.details.futureDates)) {
        return fecha.format(this.addDays(new Date(), hotelSettings.details.futureDates), 'YYYY-MM-DD');
      } else {
        return fecha.format(this.addDays(new Date(), 2 * 365), 'YYYY-MM-DD');
      }
    },
    firstDayOfWeek() {
      return isNil(this.$options.i18n[this.locale]) ? 'mo' : this.$options.i18n[this.locale].firstDayOfWeek;
    },
    weekdays() {
      return this.getDaysOfWeek(this.locale);
    },
    firstDayOfMinMonth() {
      const minDateDate = fecha.parse(this.minDate, 'YYYY-MM-DD');
      return !this.mounted ? null : new Date(minDateDate.getFullYear(), minDateDate.getMonth(), 1);
    },
    visibleMonths: function () {
      const ret = [];
      ret.push(this.firstDayOfMinMonth);

      const maxDate = isNil(this.maxDate) ? null : new Date(this.maxDate);
      for (let $i = 1; $i < Math.min(this.maxMonths, 40); $i++) {
        const firstDayOfMonth = new Date(this.firstDayOfMinMonth.getFullYear(), this.firstDayOfMinMonth.getMonth() + $i, 1);
        if (isNil(maxDate) || firstDayOfMonth <= maxDate) {
          ret.push(firstDayOfMonth);
        }
      }
      return ret;
    },
    loaded: function () {
      return this.mounted;
    },
    colorStyles: function () {
      return {
        '--unavailable-color': this.unavailableColor,
        '--unavailable-text-color': this.unavailableTextColor,
        '--border-color': this.borderColor,
      };
    },
    canScrollRight: function () {
      return this.visibleMonth !== Math.min(this.maxMonths, 40) - this.responsiveMonths;
    },
    canScrollLeft: function () {
      return this.visibleMonth !== 0;
    },
    monthsMarginComputed: function () {
      return `${this.monthsMargin}px`;
    },
    selectionActive: function () {
      return !isNil(this.selection.startDate) && isNil(this.selection.endDate);
    },
    selectedDates: function () {
      if (isNil(this.selection.startDate) || (isNil(this.selection.endDate) && isNil(this.selection.hoverDate))) {
        return [];
      }
      let startDate = fecha.parse(this.selection.startDate, 'YYYYMMDD');
      let endDate = isNil(this.selection.hoverDate) ? fecha.parse(this.selection.endDate, 'YYYYMMDD') : fecha.parse(this.selection.hoverDate, 'YYYYMMDD');

      if (startDate > endDate) {
        const tmp = startDate;
        startDate = endDate;
        endDate = tmp;
      }

      const ret = [];

      let tmp = startDate;

      for (let i = 0; !(tmp > endDate); i++) {
        const tmpFormatted = fecha.format(tmp, 'YYYY-MM-DD');
        ret.push(tmpFormatted);
        tmp = this.addDays(tmp, 1);
      }

      return ret;
    },
  },

  watch: {
    locale: function () {
      this.updateLocale();
    },
    months: function () {
      this.responsiveMonths = this.months;
    },
    startDate: function (newVal) {
      // do not interfere with active selection process
      if (!isNil(this.selection.startDate) && isNil(this.selection.endDate)) return;

      this.selection.startDate = newVal;
    },
    endDate: function (newVal) {
      // do not set an invalid scenario where only end date is set
      if (isNil(this.selection.startDate)) return;

      // do not interfere with active selection process
      if (!isNil(this.selection.startDate) && isNil(this.selection.endDate)) return;

      this.selection.endDate = this.endDate;
      this.selection.hoverDate = null;
      this.checkEndDateValidity();
    },
    ari: function (newVal, oldVal) {
      if (!isNil(newVal) && isNil(oldVal)) {
        this.updateDates();
      }
    },
  },

  created: function () {
    this.$serviceBus.$on('shortcut.esc', this.cancelSelection);
    this.minDate = fecha.format(this.addDays(new Date(), 0), 'YYYY-MM-DD');

    this.selection.startDate = this.startDate;
    this.selection.endDate = this.endDate;
    this.selection.hoverDate = null;
    this.checkEndDateValidity();

    this.updateLocale();
    this.updateDates();
  },

  beforeDestroy: function () {
    this.$serviceBus.$off('shortcut.esc', this.cancelSelection);
  },

  mounted: function () {
    this.mounted = true;
  },

  methods: {
    onClickOutside: function (event) {
      if (event.target) {
        if (!isNil(event.target.closest('.nav'))) {
          return;
        }
        if (!isNil(event.target.closest('button'))) {
          return;
        }
        if (!isNil(event.target.closest('error-message'))) {
          return;
        }
        if (!isNil(event.target.closest('.button-backdrop'))) {
          return;
        }
        this.cancelSelection();
      }
    },
    cancelSelection: function (event) {
      try {
        if (!isNil(event) && !isNil(event.target) && event.target.tagName.toUpperCase() === 'BUTTON') return;
        if (!isNil(event) && !isNil(event.srcElement) && event.srcElement.tagName.toUpperCase() === 'BUTTON') return;
      } catch (ignore) { /* ignore */ }
      this.selection = {
        startDate: null,
        endDate: null,
        hoverDate: null,
      };

      this.$emit('update:startDate', null);
      this.$emit('update:endDate', null);
    },
    updateDates: function () {
      if (!this.isNil(this.hotel)) {
        let url = `${baseUrl}/availability/${this.hotel}/hotel?filter=unavailable&dateFormat=iso&array=true`;

        if (!this.isNil(this.roomType)) {
          url = `${baseUrl}/rest/availability/${this.hotel}/hotel/${this.roomType}?filter=unavailable&dateFormat=iso&array=true`;
        }

        if (!this.isNil(this.roomNumber)) {
          url = `${baseUrl}/rest/availability/${this.hotel}/hotel/room/${this.roomNumber}?filter=unavailable&dateFormat=iso&array=true`;
        }

        return fetch(url)
          .then((result) => {
            result.json().then((myJson) => {
              this.disabledDates = myJson;

              const yesterday = new Date();
              yesterday.setDate(yesterday.getDate() - 1);

              this.disabledDates.push(fecha.format(yesterday, 'YYYY-MM-DD'));

              if (!isNil(this.ari) && !isNil(this.ari.byDate)) {
                Object.keys(this.ari.byDate).forEach((itDate) => {
                  if (this.ari.byDate[itDate].bookable === false) {
                    const itDateIso = fecha.format(fecha.parse(itDate, 'YYYYMMDD'), 'YYYY-MM-DD');
                    this.disabledDates.push(itDateIso);
                  }
                });
              }

              const itDate = new Date(this.firstDayOfMinMonth.valueOf());

              this.arrivalOnlyDates = [];
              this.departureOnlyDates = [];
              this.noArrivalDates = [];

              const previousDate = new Date(itDate.valueOf());
              const nextDate = new Date(itDate.valueOf());

              previousDate.setDate(previousDate.getDate() - 1);
              nextDate.setDate(nextDate.getDate() + 1);

              for (let $i = 0; $i < (366 * 3 + 31); $i++) {
                if (!this.disabledDates.includes(fecha.format(itDate, 'YYYY-MM-DD')) && this.disabledDates.includes(fecha.format(previousDate, 'YYYY-MM-DD'))) {
                  this.arrivalOnlyDates.push(fecha.format(itDate, 'YYYY-MM-DD'));
                }
                if (this.disabledDates.includes(fecha.format(itDate, 'YYYY-MM-DD')) && !this.disabledDates.includes(fecha.format(previousDate, 'YYYY-MM-DD'))) {
                  this.departureOnlyDates.push(fecha.format(itDate, 'YYYY-MM-DD'));
                }

                if (!isNil(this.ari) && !isNil(this.ari.byDate) && !isNil(this.availability)) {
                  const hmDayString = fecha.format(itDate, 'YYYYMMDD');
                  const ari = this.ari.byDate[hmDayString];
                  if (!isNil(ari) && !isNil(ari.fplos) && ari.fplos.indexOf(true) >= 1) {
                    const minLOS = ari.fplos.indexOf(true) + 1;
                    if (minLOS > 1 && !isEmpty(this.availability)) {
                      const anyAvailable = this.roomTypeIds.some((roomTypeId) => {
                        const roomTypeAvailability = this.availability[roomTypeId];
                        if (!isEmpty(roomTypeAvailability)) {
                          const date = fecha.parse(hmDayString, 'YYYYMMDD');
                          for (let t = 0; t < minLOS; t++) {
                            const itInnerDate = this.addDays(date, t);
                            const itDateString = fecha.format(itInnerDate, 'YYYYMMDD');
                            if (!isNil(roomTypeAvailability.availability) && roomTypeAvailability.availability[itDateString] === false) {
                              return false;
                            }
                          }
                        }
                        return true;
                      });

                      if (!anyAvailable) {
                        this.noArrivalDates.push(fecha.format(itDate, 'YYYY-MM-DD'));
                      }
                    }
                  }
                }

                itDate.setDate(itDate.getDate() + 1);
                previousDate.setDate(previousDate.getDate() + 1);
                nextDate.setDate(nextDate.getDate() + 1);
              }

              this.checkEndDateValidity();
              this.datesLoaded = true;
            });
          });
      } else {
        return Promise.reject();
      }
    },
    getDaysOfWeek(locale = 'en-US', length = 'short') {
      const shortWeekdays = Object.keys(this.weekdayDateMap);

      return shortWeekdays.map((shortName) => this.getDayOfWeek(shortName, locale, length));
    },
    getDayOfWeek(shortName, locale = 'en-US', length = 'short') {
      return new Intl.DateTimeFormat(locale, { weekday: length }).format(this.weekdayDateMap[shortName]);
    },
    addDays(date, days) {
      const ret = new Date(date);
      ret.setDate(ret.getDate() + days);
      return ret;
    },
    clearSelection() {
      this.selection.startDate = null;
      this.selection.endDate = null;
      this.selection.hoverDate = null;
      this.$emit('clear', this.selection);
    },
    onHoverDate($event, month) {
      const day = $event.day;
      if (!isNil($event.month)) {
        month = $event.month;
      }

      const date = isNil(day) ? null : new Date(month.getFullYear(), month.getMonth(), day);

      // if date is invalid date getTime() returns NaN and NaN - NaN = NaN which is not equal to 0
      if (isNil(date) || (date.getTime() - date.getTime()) !== 0) return;

      if (this.$store.getters.hotel.details.onlineBookingSettings.extendedAriValidation === true && !isNil(this.ari) && !isNil(this.ari.byDate)) {
        if (date <= fecha.parse(this.selection.startDate, 'YYYYMMDD')) return;
        const ari = this.ari.byDate[fecha.format(date, 'YYYYMMDD')];
        if (ari.departure === false) {
          return;
        }
        const minlos = Math.max(1, (ari.fplos.indexOf(true) + 1));
        const earliestDepartureDate = this.addDays(fecha.parse(this.selection.startDate, 'YYYYMMDD'), minlos);
        if (date < earliestDepartureDate) {
          return;
        }
      }

      if (isNil(date) || (date.getTime() - date.getTime()) !== 0) {
        return;
      }

      if (!isNil(this.selection.startDate) && isNil(this.selection.endDate)) {
        const tempHoverDate = fecha.format(date, 'YYYYMMDD');
        // if (tempHoverDate === this.selection.startDate) {
        //   date = this.addDays(date, 1);
        // }
        this.selection.hoverDate = fecha.format(date, 'YYYYMMDD');
        // this.$emit('update:endDate', this.selection.hoverDate);
        this.$emit('update:endDate', this.selection.endDate);
      } else {
        this.selection.hoverDate = null;
        this.$emit('update:endDate', this.selection.endDate);
      }

      this.checkEndDateValidity();
    },
    onSelectDate($event, month, dateType = 'start') {
      const day = $event.day;
      if (!isNil($event.month)) {
        month = $event.month;
      }

      let date = isNil(day) ? null : new Date(month.getFullYear(), month.getMonth(), day);

      // if date is invalid date getTime() returns NaN and NaN - NaN = NaN which is not equal to 0
      if (isNil(date) || (date.getTime() - date.getTime()) !== 0) {
        if (!isNil(this.selection.hoverDate)) {
          date = fecha.parse(this.selection.hoverDate, 'YYYYMMDD');
        } else {
          return;
        }
      }

      if (dateType === 'start' && (isNil(this.selection.startDate) || !isNil(this.selection.endDate))) {
        this.selection.startDate = fecha.format(date, 'YYYYMMDD');
        this.selection.endDate = null;
        // this.selection.hoverDate = fecha.format(this.addDays(date, 1), 'YYYYMMDD');
        this.selection.hoverDate = fecha.format(date, 'YYYYMMDD');
      } else if (dateType === 'end' && (!isNil(this.selection.startDate) && isNil(this.selection.endDate))) {
        if (this.$store.getters.hotel.details.onlineBookingSettings.extendedAriValidation === true && !isNil(this.ari) && !isNil(this.ari.byDate)) {
          if (date < fecha.parse(this.selection.startDate, 'YYYYMMDD')) return;
        }

        this.selection.endDate = fecha.format(date, 'YYYYMMDD');
        this.selection.hoverDate = null;

        if (this.selection.endDate === this.selection.startDate) {
          this.selection.endDate = fecha.format(this.addDays(date, 1), 'YYYYMMDD');
        }

        this.checkEndDateValidity();

        if (this.selection.endDate < this.selection.startDate) {
          const tmp = this.selection.startDate;
          this.selection.startDate = this.selection.endDate;
          this.selection.endDate = tmp;
        }

        if (this.$store.getters.hotel.details.onlineBookingSettings.extendedAriValidation === true && !isNil(this.ari) && !isNil(this.ari.byDate)) {
          const ari = this.ari.byDate[this.selection.startDate];
          const minlos = Math.max(1, (ari.fplos.indexOf(true) + 1));
          const earliestDepartureDate = this.addDays(fecha.parse(this.selection.startDate, 'YYYYMMDD'), minlos);
          if (minlos > 1 && fecha.parse(this.selection.endDate, 'YYYYMMDD') < earliestDepartureDate) {
            this.$serviceBus.$emit('infoBox.msg', this.$t('availabilityCalendar.infoMsgMinLOS', [minlos]));
          } else {
            this.$serviceBus.$emit('infoBox.hide');
          }
        }

        this.$emit('select', this.selection);
      }

      if (!isNil(this.selection.startDate) && !isNil(this.selection.endDate) && this.selection.endDate < this.selection.startDate) {
        const tmp = this.selection.startDate;
        this.selection.startDate = this.selection.endDate;
        this.selection.endDate = tmp;
      }

      this.$emit('update:startDate', isNil(this.selection.startDate) ? null : this.selection.startDate);
      // this.$emit('update:endDate', isNil(this.selection.endDate) ? this.selection.hoverDate : this.selection.endDate);
      this.$emit('update:endDate', this.selection.endDate);
    },
    imageButtonStyle(imageButtonPath) {
      return imageButtonPath ? `background-image: url('${imageButtonPath}')` : null;
    },
    onresize(event) {
      const availableWidth = event.width;

      for (let i = this.months; i >= 1; i--) {
        if (availableWidth >= this.visibleSize(i)) {
          this.responsiveMonths = i;
          return;
        }
      }

      this.responsiveMonths = 1;
    },
    visibleSize(months) {
      return (247 + 6 * 7) * months + this.monthsMargin * (months - 1) + 2;
    },
    scrollLeft() {
      this.visibleMonth = Math.max(0, this.visibleMonth - 1);
      this.updateScroll();
    },
    scrollRight() {
      this.visibleMonth = Math.min(Math.min(this.maxMonths, 40) - this.responsiveMonths, this.visibleMonth + 1);
      this.updateScroll();
    },
    updateScroll() {
      const width = this.$refs.container.scrollWidth;
      const offset = 247 + this.monthsMargin + 6 * 7;
      const newScroll = Math.min(width, offset * this.visibleMonth);

      if (typeof this.$refs.container.scrollTo === 'undefined') {
        this.$refs.container.scrollLeft = newScroll;
      } else {
        this.$refs.container.scrollTo({ top: 0, left: newScroll, behavior: 'smooth' });
      }
    },
    updateLocale() {
      fecha.i18n = {
        ...this.$options.i18n[this.locale],
      };
    },
    isNil(val) {
      return (val === null || typeof val === 'undefined');
    },
    formatMonth(month) {
      return Intl.DateTimeFormat(this.locale, { month: 'long', year: 'numeric' }).format(month);
    },
    checkEndDateValidity() {
      if (isNil(this.selection.startDate)) return;
      if (isNil(this.selection.hoverDate) && isNil(this.selection.endDate)) return;
      let startDate = fecha.parse(this.selection.startDate, 'YYYYMMDD');
      let endDate = isNil(this.selection.hoverDate) ? fecha.parse(this.selection.endDate, 'YYYYMMDD') : fecha.parse(this.selection.hoverDate, 'YYYYMMDD');

      let swap = false;

      if (startDate > endDate) {
        const tmp = startDate;
        startDate = endDate;
        endDate = tmp;
        swap = true;
      }

      if (swap) {
        let tmp = endDate;

        for (let i = 0; tmp >= startDate; i++) {
          const tmpFormatted = fecha.format(tmp, 'YYYY-MM-DD');
          if (!this.allowDisabled && this.disabledDates.includes(tmpFormatted)) {
            tmp = this.addDays(tmp, 1);
            if (isNil(this.selection.hoverDate)) {
              this.selection.endDate = fecha.format(tmp, 'YYYYMMDD');
            } else {
              this.selection.hoverDate = fecha.format(tmp, 'YYYYMMDD');
            }
            // this.$emit('update:endDate', isNil(this.selection.endDate) ? this.selection.hoverDate : this.selection.endDate);
            this.$emit('update:endDate', this.selection.endDate);
            return;
          }
          tmp = this.addDays(tmp, -1);
        }
      } else {
        let tmp = startDate;

        for (let i = 0; tmp <= endDate; i++) {
          const tmpFormatted = fecha.format(tmp, 'YYYY-MM-DD');
          if (!this.allowDisabled && this.disabledDates.includes(tmpFormatted)) {
            if (isNil(this.selection.hoverDate)) {
              this.selection.endDate = fecha.format(tmp, 'YYYYMMDD');
            } else {
              this.selection.hoverDate = fecha.format(tmp, 'YYYYMMDD');
            }
            // this.$emit('update:endDate', isNil(this.selection.endDate) ? this.selection.hoverDate : this.selection.endDate);
            this.$emit('update:endDate', this.selection.endDate);
            return;
          }
          tmp = this.addDays(tmp, 1);
        }
      }
    },
    isEmpty(obj) {
      return isEmpty(obj);
    },
  },
};
</script>

<style lang="scss">
  @import '~@/styles/import';

  @mixin first-day-of-week() {
    $weekdays: 'mo','tu','we','th','fr','sa','su';

    @each $weekday in $weekdays {
      $offset: 7 - index($weekdays, $weekday);

      @if $offset >= 7 {
        $offset: $offset - 7;
      }

      &.first-day-of-week {
        &--#{$weekday} {

          @for $i from 1 through length($weekdays) {
            $itWeekday: nth($weekdays, $i);
            $start: $i + $offset + 1;

            @if $start > 7 {
              $start: $start - 7;
            }

            .weekday:nth-of-type(#{$i}) {
              grid-column-start: $start;
            }
          }

          .day {
            @for $i from 1 through length($weekdays) {
              $itWeekday: nth($weekdays, $i);
              $start: $i + $offset + 1;

              @if $start > 7 {
                $start: $start - 7;
              }

              &.#{$itWeekday} {
                grid-column-start: $start;
              }
            }
          }
        }
      }
    }
  }

  .wrapper {
    @apply hidden;
    // display: none;
    --border-color: $grayLight;
    --font-size: 1.2rem;
    --line-height: 1.5;
    min-width: 220px;
    max-width: 600px;
    font-size: var(--font-size);
    line-height: var(--line-height);

    @supports(display: grid) {
      @apply grid select-none;
      grid-template-columns: 1fr;
      // display: grid;
      // user-select: none;

      .button {
        padding: 10px 14px;
        font-size: 120%;
        line-height: 90%;
        border: 1px solid lightgray;
        border: 1px solid var(--border-color);
        text-align: center;
        font-weight: bold;
        cursor: pointer;
        transition: background-color 0.3s ease, box-shadow 0.1s ease;

        &:hover {
          background-color: #efefef;
        }

        &:active {
          box-shadow: inset 0px 0px 10px 0px #ccc;
        }

        &__wrapper {
          display: grid;
          grid-template-columns: min-content;

          &--right {
            justify-content: right;
          }
        }

        &--next {
          float: right;
        }

        &--image {
          border: none;
          padding: 0;
          width: 48px;
          height: 48px;
          background-position: center center;
          background-size: contain;
          background-repeat: no-repeat;

          &:hover {
            background-color: transparent;
          }

          &:active {
            box-shadow: none;
          }
        }
      }
    }
  }

  .calendar__container {
    grid-column-start: span 2;

    overflow: hidden;
    user-select: none;
  }

  .calendar {
    @apply grid;
    // display: grid;
    grid-template-columns: 1fr;
    grid-gap: var(--months-margin);
    grid-row-gap: 35px;

    &--multi-line {
      grid-template-columns: 1fr;
    }

    &--border {
        .month {
            .day {
                box-shadow: 0 0 0 1px white;
                box-shadow: 0 0 0 1px var(--border-color);
                &.disabled {
                    box-shadow: 0 0 0 1px var(--unavailable-color);
                }
                &.departure-only {
                    &:before {
                        box-shadow: 1px 1px 0 0px var(--unavailable-color);
                    }
                }
                &.arrival-only {
                    &:before {
                        box-shadow: -1px -1px 0 0px var(--unavailable-color);
                    }
                }
            }
        }
    }

    .month {
      @apply grid p-1 box-border z-0;
      // display: grid;
      // padding: 1px;
      // box-sizing: border-box;
      grid-template-columns: repeat(7, 1fr);
      max-width: 100%;
      grid-gap: 1px;
      grid-auto-rows: 35px;
      grid-template-rows: 35px repeat(6, max-content);

      grid-row-gap: 7px;
      grid-column-gap: 7px;

      &--weekdays {
        grid-template-rows: 50px;
        position: sticky;
        top: 0;

        @apply z-20;
        .weekdays {
          @apply bg-grayVeryLight;

          .weekday {
            @apply bg-grayVeryLight;
          }
        }
      }

      .month-name {
        @apply font-semibold text-14 pb-16;
        grid-column-start: span 7;
      }

      .weekdays {
        @apply grid;
        grid-column-start: 1;
        grid-column-end: span 7;
        grid-template-columns: repeat(7, 1fr);
        // margin-bottom: 5px;
        // height: 35px;
      }

      .weekday {
        grid-row-start: 2;
        margin-left: -1px;
        margin-right: -1px;
        margin-bottom: -1px;

        @apply text-12 py-16 text-center text-gray font-medium border-grayLight border-t border-b;
      }

      .day {
        // line-height: 36px;
        // position: relative;
        // border: 2px solid var(--border-color);
        // border-radius: 3px;
        // cursor: pointer;

        @apply cursor-pointer relative text-gray text-12 font-medium text-center border-2 rounded-sm border-grayLight flex items-center justify-center;

        --border-width: 2px;
        padding-bottom: calc(50% - ((var(--font-size) * var(--line-height)) / 2) - var(--border-width)); // 1.2rem = font-size; 1.5 = line-height; 2px = border-width
        padding-top: calc(50% - ((var(--font-size) * var(--line-height)) / 2) - var(--border-width)); // 1.2rem = font-size; 1.5 = line-height; 2px = border-width
        //display: grid;
        //grid-auto-rows: 1fr;
        //padding-bottom: calc(50% - ((var(--font-size) * var(--line-height) * 2) / 2) - var(--border-width)); // 1.2rem = font-size; 1.5 = line-height; 2px = border-width
        //padding-top: calc(50% - ((var(--font-size) * var(--line-height) * 2) / 2) - var(--border-width)); // 1.2rem = font-size; 1.5 = line-height; 2px = border-width
        padding-bottom: calc(50% - ((var(--font-size) * var(--line-height)) / 2) - var(--border-width)); // 1.2rem = font-size; 1.5 = line-height; 2px = border-width
        padding-top: calc(50% - ((var(--font-size) * var(--line-height)) / 2) - var(--border-width)); // 1.2rem = font-size; 1.5 = line-height; 2px = border-width

        &:not(.has-price) {
          .day--price {
            display: none;
          }
        }

        &.has-price {
          @media (min-width: 446px) and (max-width: 767px) {
            padding-bottom: calc(60% - ((var(--font-size) * var(--line-height)) / 2) - var(--border-width)); // 1.2rem = font-size; 1.5 = line-height; 2px = border-width
            padding-top: calc(40% - ((var(--font-size) * var(--line-height)) / 2) - var(--border-width)); // 1.2rem = font-size; 1.5 = line-height; 2px = border-width
          }
          @media (min-width: 980px) {
            padding-bottom: calc(55% - ((var(--font-size) * var(--line-height)) / 2) - var(--border-width)); // 1.2rem = font-size; 1.5 = line-height; 2px = border-width
            padding-top: calc(45% - ((var(--font-size) * var(--line-height)) / 2) - var(--border-width)); // 1.2rem = font-size; 1.5 = line-height; 2px = border-width
          }

          .day--price {
            @apply text-10 text-grayBlue;
            position: absolute !important;
            margin-left: auto;
            margin-right: auto;
            bottom: 10%;
            display: none;
            @media (min-width: 446px) and (max-width: 767px) {
              display: block;
            }
            @media (min-width: 980px) {
              display: block;
            }
            @media (min-width: 580px) and (max-width: 767px) {
              bottom: 25%;
            }
            @media (min-width: 1024px) and (max-width: 1100px) {
              bottom: 15%;
            }
            @media (min-width: 1101px) {
              bottom: 20%;
            }
          }
        }

        &.disabled, &.past {
          .day--price {
            display: none;
          }
        }

        &.selected {
          .day--price {
            @apply text-dark;
          }

          &--start, &--end {
            .day--price {
              @apply text-white;
            }
          }
        }

        .day--text {
          @apply text-12 z-50 block relative;
          // z-index: 999;
          // display: block;
          // position: relative;
        }

        &.selected {
          // background-color: var(--primary-color);
          // position: relative;
          @apply relative border-transparent text-dark;

          &:before {
            content: ' ';
            @apply absolute block bg-primary opacity-40 border-2 rounded-0 border-primary;
            left: -5.5px;
            top: -2px;
            bottom: -2px;
            right: -5.5px;
            // display: block;
            // position: absolute;
            // background-color: #CCCDCF;

          }

          &--start, &--end:not(.selected--end--unconfirmed) {
            @apply bg-primary text-white border-2 border-primary rounded-0;
            // background-color: #383F45;
            // color: white;

            &:before {
              content: ' ';
              @apply block absolute;
              left: -5.5px;
              top: -2px;
              bottom: -2px;
              right: -5.5px;
            }

            &.arrival-only, &.departure-only {
              &:before {
                background: none !important;
              }
            }
          }

          &--start {
            @apply rounded-l-sm;
            &:after {
              content: '';
              top: -2px;
              bottom: -2px;
              right: -5.5px;
              width: 5.5px;
              @apply bg-primary absolute;
            }
            &:before {
              @apply rounded-l-sm left-0;
              // border-top-left-radius: 3px;
              // border-bottom-left-radius: 3px;
              // left: 0px;
            }

            &.selected--end {
              @apply rounded-r-sm rounded-l-sm;
              &:before {
                @apply right-0 left-0 rounded-r-sm rounded-l-sm;
              }
              &:after {
                display: none;
              }
            }
          }

          &--end:not(.selected--start) {
            @apply rounded-r-sm;
            &:after {
              content: '';
              top: -2px;
              bottom: -2px;
              left: -5.5px;
              width: 5.5px;
              @apply bg-primary absolute;
            }
            &:before {
              @apply rounded-r-sm right-0;
              // border-top-right-radius: 3px;
              // border-bottom-right-radius: 3px;
              // right: 0px;
            }

            &.selected--end--unconfirmed {
              @apply text-dark;
              background-color: transparent!important;

              &:before {
                @apply bg-primary opacity-40 border-2 border-primary;

                background: var(--color-primary);
                top: -2px;
                bottom: -2px;
                right: -3.5px;
              }

              &:after {
                @apply opacity-0;
              }
            }
          }
        }

        &.past {
          @apply cursor-not-allowed border-transparent text-grayBlue bg-transparent;
        }

        &.disabled {
          background-color: $grayLight;
          background-color: var(--unavailable-color);
          //@apply bg-grayLight pointer-events-none;
          @apply cursor-not-allowed;
          color: $grayBlue;
          color: var(--unavailable-text-color);
        }
        &.departure-only {
          &:before {
            content: ' ';
            @apply absolute top-0 left-0 bottom-0 right-0;
            background: linear-gradient(to right bottom, transparent 50%, $grayLight 50%);
            background: linear-gradient(to right bottom, transparent 50%, var(--unavailable-color) 50%);
          }

          &.no-click {
            //@apply pointer-events-none;
            @apply cursor-not-allowed;
          }
        }
        &.arrival-only {
          &:before {
            content: ' ';
            @apply absolute top-0 left-0 bottom-0 right-0;
            background: linear-gradient(to left top, transparent 50%, $grayLight 50%);
            background: linear-gradient(to left top, transparent 50%, var(--unavailable-color) 50%);
          }
        }
      }

      @include first-day-of-week();
    }

    &:not(.calendar--start-date-selected), &.calendar--start-date-selected.calendar--end-date-selected {
      .month {
        .day {
          &:not(.selected) {
            &.restriction-arrival-closed {
              @apply border-solid cursor-not-allowed;
              color: $grayBlue;
              color: var(--unavailable-text-color);
            }
            &.available-not-bookable-due-to-minlos {
              color: #039be5;
              background: #1bc176;
            }
          }
        }
      }
    }
    &.calendar--start-date-selected:not(.calendar--end-date-selected) {
      .month {
        .day {
          &:not(.selected) {
            &.restriction-departure-closed {
              @apply border-solid cursor-not-allowed;
              color: $grayBlue;
              color: var(--unavailable-text-color);
            }
          }
        }
      }
    }

  }
</style>
