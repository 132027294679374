/* eslint-disable implicit-arrow-linebreak */
import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '../views/HomeView.vue';
import EmptyColumn2 from '@/views/EmptyColumn2';
import Nav from '@/components/lib/Nav/MainNav';
import ConfirmationNav from '@/components/lib/Nav/ConfirmationNav';
import { isEmpty } from 'lodash/lang';
import ErrorView from '@/views/ErrorView';
import EmptyNav from '@/components/lib/Nav/EmptyNav';
import DisabledView from '@/views/DisabledView';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'error',
    components: {
      nav: EmptyNav,
      default: ErrorView,
    },
  },
  {
    path: '/:encodedHotelId/disabled',
    name: 'disabled',
    components: {
      nav: EmptyNav,
      default: DisabledView,
    },
  },
  {
    path: '/:encodedHotelId',
    name: 'home',
    components: {
      nav: Nav,
      default: HomeView,
      'column-2': EmptyColumn2,
    },
    meta: {
      wide: false,
    },
    query: {
      arrivalDate: null,
      departureDate: null,
      preferredRoomTypeId: null,
      preferredRatePlanId: null,
      lang: null,
      source: null,
      debug: 0,
    },
  },
  {
    path: '/:encodedHotelId/room-count',
    name: 'roomcount',
    components: {
      nav: Nav,
      default: () => import(/* webpackChunkName: "room-count" */ '../views/RoomCountView.vue'),
      'column-2': EmptyColumn2,
    },
  },
  {
    path: '/:encodedHotelId/room-select/:roomRequestId',
    name: 'roomselect',
    components: {
      nav: Nav,
      default: () => import(/* webpackChunkName: "room-select" */ '../views/RoomSelectView.vue'),
      'column-2': EmptyColumn2,
    },
    meta: {
      wide: true,
    },
  },
  {
    path: '/:encodedHotelId/room-select/:roomRequestId/:roomTypeId',
    name: 'rateselect',
    components: {
      nav: Nav,
      default: () => import(/* webpackChunkName: "room-select" */ '../views/RoomSelectView.vue'),
      modal: () => import(/* webpackChunkName: "room-select" */ '../views/RoomModalView.vue'),
      'column-2': EmptyColumn2,
    },
    meta: {
      wide: true,
    },
  },
  {
    path: '/:encodedHotelId/room-select/:roomRequestId/:roomTypeId/:ratePlanId/addons',
    name: 'roomaddons',
    components: {
      nav: Nav,
      default: () => import(/* webpackChunkName: "room-select" */ '../views/RoomSelectView.vue'),
      modal: () => import(/* webpackChunkName: "room-select" */ '../views/RoomAddonsView.vue'),
      'column-2': EmptyColumn2,
    },
    meta: {
      wide: true,
    },
  },
  {
    path: '/:encodedHotelId/room-notes',
    name: 'roomnotes',
    components: {
      nav: Nav,
      default: () => import(/* webpackChunkName: "room-notes" */ '../views/RoomNotesView.vue'),
      'column-2': EmptyColumn2,
    },
  },
  {
    path: '/:encodedHotelId/contact',
    name: 'contact',
    components: {
      nav: Nav,
      default: () => import(/* webpackChunkName: "contact-view" */ '../views/ContactView.vue'),
      'column-2': () => import(/* webpackChunkName: "contact-view" */ '../views/ContactColumn2.vue'),
    },
  },
  {
    path: '/:encodedHotelId/summary',
    name: 'summary',
    components: {
      nav: Nav,
      default: () => import(/* webpackChunkName: "summary-view" */ '../views/SummaryView.vue'),
      'column-2': EmptyColumn2,
    },
  },
  {
    path: '/:encodedHotelId/payment/:confirmationNumber',
    name: 'payment',
    components: {
      nav: ConfirmationNav,
      default: () => import(/* webpackChunkName: "payment-view" */ '../views/PaymentView.vue'),
      'column-2': EmptyColumn2,
    },
  },
  {
    path: '/:encodedHotelId/confirmation/:confirmationNumber',
    name: 'confirmation',
    components: {
      nav: ConfirmationNav,
      default: () => import(/* webpackChunkName: "confirmation-view" */ '../views/ConfirmationView.vue'),
      'column-2': EmptyColumn2,
    },
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  if ((isEmpty(to.params) || isEmpty(to.params.encodedHotelId)) && !isEmpty(from) && !isEmpty(from.params) && !isEmpty(from.params.encodedHotelId)) {
    next({
      ...to,
      params: {
        ...to.params,
        encodedHotelId: from.params.encodedHotelId,
      },
    });
  } else {
    next();
  }
});

export default router;
