<template>
  <router-link :to="to"
               active-class="active"
               :exact="exact"
               :class="{ 'complete': complete }"
               @click.native="$serviceBus.$emit('navigation.click')"
  >
    <h3>
      {{ title }}
    </h3>
    <span class="check">
      <check-icon size="16" class="text-primary mt-4"></check-icon>
    </span>
    <template v-if="complete">
      <span class="text-gray text-selected text-12 mt-8">
        <slot name="complete" />
      </span>
    </template>
    <template v-else>
      <span class="text-gray text-12 mt-8">
        <slot />
      </span>
    </template>
  </router-link>
</template>

<script>

import { CheckIcon } from 'vue-feather-icons';

export default {
  name: 'NavItem',

  components: { CheckIcon },

  props: {
    to: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      default: '-',
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    exact: {
      type: Boolean,
      default: true,
    },
    complete: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>

</style>
