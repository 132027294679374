export default {
  hotel: {
    get: ($hotelId) => `/wbe/v2.0/${$hotelId}/hotel`,
    availability: ($hotelId) => `/wbe/v2.0/${$hotelId}/availability`,
    overallAvailability: ($hotelId) => `/availability/${$hotelId}`,
    ari: ($hotelId) => `/wbe/v2.0/${$hotelId}/ari`,
    quote: ($hotelId) => `/wbe/v2.0/${$hotelId}/quote`,
    reservationRequest: ($hotelId) => `/wbe/v2.0/${$hotelId}/reservationRequest`,
    reservationStatus: ($hotelId, $confirmationCode) => `/wbe/v2.0/${$hotelId}/reservation/${$confirmationCode}`,
    privacy: ($hotelId, $locale) => `/wbe/v2.0/${$hotelId}/legal/privacy/${$locale}.html`,
    imprint: ($hotelId, $locale) => `/wbe/v2.0/${$hotelId}/legal/imprint/${$locale}.html`,
    businessTerms: ($hotelId, $locale) => `/wbe/v2.0/${$hotelId}/legal/businessTerms/${$locale}.html`,
  },
};
