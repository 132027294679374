// define a mixin object
import { cloneDeep, isEmpty, isFunction, isNil, isNumber } from 'lodash/lang';
import { replace, endsWith, trimEnd } from 'lodash/string';
import ServiceUrls from '@/lib/ServiceUrls';
import { find, keyBy, map, size } from 'lodash/collection';

import { throttle } from 'lodash/function';
import baseUrl from '@/lib/urls';
import { sum } from 'lodash/math';
import { uniq } from 'lodash/array';

export default {

  data() {
    return {
      serviceUrls: ServiceUrls,
      brokenImageUrl: 'https://hotelsoftware.twic.pics/img/assets/bell.jpg?twic=v1/focus=auto/cover-max=800/quality-max=90/output=preview',
      quote: throttle(this._quote, 250, { leading: false, trailing: true }),
      isQuoting: false,
    };
  },

  filters: {
    nl2br: function (value) {
      return replace(value, /[\r\n]+/gi, '<br />');
    },
    toCurrency: function (value) {
      let ret = `${(isNil(value) ? 0 : value).toLocaleString(
        window.i18n.locale,
        {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        },
      )}`;

      if (endsWith(ret, '00')) {
        ret = trimEnd(ret, '0');
        ret += '-';
      }

      return ret;
    },
    toCurrencyDigits: function (value) {
      const ret = `${(isNil(value) ? 0 : value).toLocaleString(
        window.i18n.locale,
        {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        },
      )}`;

      return ret;
    },
    toNumber: function (value) {
      const ret = `${(isNil(value) ? 0 : value).toLocaleString(
        window.i18n.locale,
        {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        },
      )}`;

      return ret;
    },
  },

  computed: {
    discountCode() {
      if (this.allowDiscountCodes && !isEmpty(this.$store.getters.discountCode)) return this.$store.getters.discountCode;
      else return null;
    },
    allowDiscountCodes() {
      try {
        return this.$store.getters.hotel.details.onlineBookingSettings.discountCodes === true;
      } catch (ignore) {
        return false;
      }
    },
    trackingEnabled() {
      return this.$store.getters.tracking === true;
    },
    preferredRoomTypeId() {
      return this.$store.getters.preferredRoomTypeId;
    },
    preferredRatePlanId() {
      return this.$store.getters.preferredRatePlanId;
    },
    wide() {
      return !isNil(this.$route.meta) && this.$route.meta.wide === true;
    },
    hotelId: function () {
      return this.$route.params.encodedHotelId;
    },
    hotel: function () {
      return this.$store.getters.hotel;
    },
    settings: function () {
      return isNil(this.hotel)
        ? {
          enabled: true,
          hideBusinessTermsCheckbox: false,
          showPrivacyCheckbox: true,
          requireAddress: true,
          requirePhoneNumber: false,
          askForCompanyName: true,
          roomSelectionDisplay: 'Wide',
          lowAvailabilityMessageThreshold: 4,
          colors: {
          },
          paymentConfiguration: {
            primaryPaymentServiceProvider: 'Manual',
          },
        }
        : this.hotel.details.onlineBookingSettings;
    },
    locale: function () {
      return (this.$i18n.locale || 'de').substr(0, 2).toLowerCase();
    },
    roomTypeMap() {
      return keyBy(this.hotel.roomTypes, 'id');
    },
    ratePlanMap() {
      return keyBy(this.hotel.ratePlans, 'id');
    },
    inHouseServiceMap() {
      return keyBy(this.hotel.inHouseServices, 'id');
    },
    roomTypeAvailability() {
      if (isNil(this.$store.getters.roomTypeAvailability)) return null;
      return Object.values(this.$store.getters.roomTypeAvailability)
        .map((it) => {
          if (isNil(this.roomTypeMap[it.roomTypeId])) {
            return null;
          } else {
            return {
              roomTypeId: it.roomTypeId,
              minOccupancy: this.roomTypeMap[it.roomTypeId].minOccupancy,
              expectedOccupancy: this.roomTypeMap[it.roomTypeId].expectedOccupancy,
              maxOccupancy: this.roomTypeMap[it.roomTypeId].maxOccupancy,
              available: it.countAvailable,
            };
          }
        })
        .filter((it) => !isNil(it));
    },
    countryNames() {
      let ret = this.$countries.getNames(this.locale);
      ret = isEmpty(ret) ? this.$countries.getNames('en') : ret;

      return Object.entries(ret).map(([k, v]) => ({
        label: v,
        code: k,
      }));
    },
    quoteResponse() {
      return this.$store.getters.quoteResponse;
    },
    roomRequests() {
      return this.$store.getters.roomRequests;
    },
    selectedShoppingCartItems() {
      return this.$store.getters.selectedShoppingCartItems;
    },
    shoppingCartByRoomRequest() {
      if (isEmpty(this.roomRequests)) return {};

      const ret = this.roomRequests.map((it) => ({
        roomRequestId: it.id,
        shoppingCart: [
          ...((this.getOffer(it.id) || {}).shoppingCart || []),
          ...((this.selectedShoppingCartItems.find((r) => r.roomRequestId === it.id) || {}).shoppingCartItems || [])
            .map((shoppingCartItem) => {
              const offer = this.getOffer(it.id);
              if (isEmpty(offer) || isEmpty(offer.bookableShoppingCartItems)) return {};
              const bookableShoppingCartItemWrapper = cloneDeep(offer.bookableShoppingCartItems.find((r) => r.shoppingCartItem.inHouseServiceId === shoppingCartItem.inHouseServiceId));
              const bookableShoppingCartItem = bookableShoppingCartItemWrapper.shoppingCartItem;
              delete bookableShoppingCartItem.rates;
              const multiplicator = shoppingCartItem.count / bookableShoppingCartItem.itemCount;
              bookableShoppingCartItem.itemCount *= multiplicator;
              bookableShoppingCartItem.prepaymentAmount = bookableShoppingCartItemWrapper.prepaymentAmount || 0;
              bookableShoppingCartItem.prepaymentAmount *= multiplicator;
              // bookableShoppingCartItem.itemPrice *= multiplicator;
              bookableShoppingCartItem.unitPriceSummary.includedTaxAmount *= multiplicator;
              bookableShoppingCartItem.unitPriceSummary.excludedTaxAmount *= multiplicator;
              bookableShoppingCartItem.unitPriceSummary.customAmount *= multiplicator;
              bookableShoppingCartItem.unitPriceSummary.originalAmount *= multiplicator;
              bookableShoppingCartItem.unitPriceSummary.amount *= multiplicator;
              bookableShoppingCartItem.unitPriceSummary.taxDetails.forEach((tax) => {
                tax.amount *= multiplicator;
                tax.baseAmount *= multiplicator;
              });
              return bookableShoppingCartItem;
            }),
        ],
      }));
      return keyBy(ret, 'roomRequestId');
    },
    shoppingCartSummaryByRoomRequest() {
      if (isEmpty(this.roomRequests)) return {};
      return keyBy(this.roomRequests.map((it) => {
        const ret = {
          roomRequestId: it.id,
          includedTaxAmount: 0,
          excludedTaxAmount: 0,
          customAmount: 0,
          originalAmount: 0,
          amount: 0,
          prepaymentAmount: 0,
        };

        this.shoppingCartByRoomRequest[it.id].shoppingCart.forEach((item) => {
          if (!isEmpty(item) && !isEmpty(item.unitPriceSummary)) {
            ret.includedTaxAmount += item.unitPriceSummary.includedTaxAmount;
            ret.excludedTaxAmount += item.unitPriceSummary.excludedTaxAmount;
            ret.customAmount += item.unitPriceSummary.customAmount;
            ret.originalAmount += item.unitPriceSummary.originalAmount;
            ret.amount += item.unitPriceSummary.amount;
            ret.prepaymentAmount += item.prepaymentAmount;
          }
        });

        return ret;
      }), 'roomRequestId');
    },
    selectedOffersMap() {
      return keyBy(this.$store.getters.selectedOffers, 'roomRequestId');
    },
    taxMap() {
      return keyBy(this.hotel.taxes, 'id');
    },
    totalAmount() {
      if (isEmpty(this.roomRequests)) return 0;
      return sum(this.roomRequests
        .map((it) => this.getOffer(it.id))
        .map((offer) => (isNil(offer) ? 0 : offer.unitPriceSummary.amount + offer.unitPriceSummary.excludedTaxAmount + this.shoppingCartSummaryByRoomRequest[offer.roomRequestId].amount + this.shoppingCartSummaryByRoomRequest[offer.roomRequestId].excludedTaxAmount)));
    },
    totalPrepaymentAmount() {
      if (isEmpty(this.roomRequests)) return 0;
      let roomPrepaymentAmount = sum(this.roomRequests
        .map((it) => this.getOffer(it.id))
        .map((offer) => (isNil(offer) ? 0 : offer.webPrepaymentAmount)));

      try {
        const shoppingCartPrepaymentAmount = sum(this.roomRequests.map((it) => this.shoppingCartSummaryByRoomRequest[it.id].prepaymentAmount || 0.0));

        roomPrepaymentAmount += shoppingCartPrepaymentAmount;
      } catch (ignore) { /* ignore */ }

      return roomPrepaymentAmount;
    },
    hotelDataUpToDate() {
      if (!isNil(this.quoteResponse)) {
        const roomTypeIds = uniq(map(this.quoteResponse.offers, 'roomTypeId'));
        const ratePlanIds = uniq(map(this.quoteResponse.offers, 'ratePlanId'));

        const hotelRoomTypeIds = uniq(map(this.hotel.roomTypes, 'id'));
        const hotelRatePlanIds = uniq(map(this.hotel.ratePlans, 'id'));

        if (!roomTypeIds.every((it) => hotelRoomTypeIds.includes(it))) return false;
        if (!ratePlanIds.every((it) => hotelRatePlanIds.includes(it))) return false;

        if (isNil(this.roomTypeAvailability)) return false;

        const availabilityRoomTypeIds = uniq(map(this.roomTypeAvailability, 'roomTypeId'));
        if (!roomTypeIds.every((it) => availabilityRoomTypeIds.includes(it))) return false;
      }

      return true;
    },
  },

  watch: {
    'hotel.details.onlineBookingSettings.enabled': function (newVal) {
      if (newVal === false) {
        this.$router.push({ name: 'disabled', params: { encodedHotelId: this.hotelId } });
      }
    },
  },

  created() {
    switch (this.$route.name) {
      case 'home':
        break;
      case 'roomcount':
        if (isNil(this.$store.getters.roomTypeAvailability)) this.$router.push({ name: 'home' });
        break;
      case 'roomselect':
        if (isNil(this.$store.getters.roomTypeAvailability)) this.$router.push({ name: 'home' });
        else if (isNil(this.$store.getters.quoteResponse)) this.$router.push({ name: 'roomcount' });
        break;
      default:
        break;
    }

    if (this.$route.name !== 'disabled') {
      try {
        if (this.hotel.details.onlineBookingSettings.enabled === false) {
          this.$router.push({ name: 'disabled', params: { encodedHotelId: this.hotelId } });
        }
      } catch (_) { /* ignore */ }
    }
  },

  methods: {
    isNil(arg) {
      return isNil(arg);
    },
    isEmpty(arg) {
      return isEmpty(arg);
    },
    _loadRoomTypeAvailability() {
      if (isNil(this.$store.getters.arrivalDate) || isNil(this.$store.getters.departureDate)) {
        this.$store.dispatch('roomTypeAvailability', null);
        return;
      }

      this.$superagent
        .get(this.serviceUrls.hotel.availability(this.hotelId))
        .query(`locale=${this.locale}&arrivalDate=${this.$store.getters.arrivalDate}&departureDate=${this.$store.getters.departureDate}`)
        .then((res) => {
          const data = keyBy(res.body.data, (it) => it.roomTypeId);
          this.$store.dispatch('roomTypeAvailability', data);
        })
        .catch((err) => {
          this.$rollbar.error(err);
          this.$store.dispatch('roomTypeAvailability', null);
        });
    },
    _quote() {
      if (isNil(this.hotel) || isNil(this.hotelId)) return Promise.resolve();
      if (isNil(this.$store.getters.arrivalDate)) return Promise.resolve();
      if (isNil(this.$store.getters.departureDate)) return Promise.resolve();
      if (this.$store.getters.arrivalDate === this.$store.getters.departureDate) return Promise.resolve();
      if (this.$store.getters.departureDate <= this.$store.getters.arrivalDate) return Promise.resolve();
      if (isNil(this.$store.getters.roomRequests) || this.$store.getters.roomRequests.length === 0) return Promise.resolve();

      if (isNil(this.$store.getters.roomTypeAvailability)) {
        this._loadRoomTypeAvailability();
      }

      if (this.isQuoting) {
        return this.quote();
      }

      this.isQuoting = true;

      return this.$superagent
        .post(this.serviceUrls.hotel.quote(this.hotelId))
        .query(`locale=${this.locale}`)
        .set('Content-Type', 'application/json')
        .send(this.$store.getters.quoteRequest)
        .then((res) => {
          this.$store.dispatch('quoteResponse', res.body.data);
          this.isQuoting = false;
        })
        .catch((err) => {
          this.isQuoting = false;
          this.$serviceBus.$emit('infoBox.msg', this.$t('errors.failedToQuote'));
          this.$rollbar.error('Failed to quote', err);
        });
    },
    executeTimeline(actions) {
      if (actions.length > 0) {
        this._executeTimelineTask(actions, 0);
      }
    },
    _executeTimelineTask(actions, i) {
      if (i >= actions.length) return;

      if (isNumber(actions[i])) {
        setTimeout(() => {
          this._executeTimelineTask(actions, (i + 1));
        }, actions[i]);
      } else if (isFunction(actions[i])) {
        actions[i]();
        this._executeTimelineTask(actions, (i + 1));
      } else {
        this._executeTimelineTask(actions, (i + 1));
      }
    },
    showImprint() {
      this.$serviceBus.$emit('openUrlModal', `${baseUrl}${ServiceUrls.hotel.imprint(this.hotelId, this.locale)}`);
    },
    showPrivacy() {
      this.$serviceBus.$emit('openUrlModal', `${baseUrl}${ServiceUrls.hotel.privacy(this.hotelId, this.locale)}`);
    },
    showBusinessTerms() {
      this.$serviceBus.$emit('openUrlModal', `${baseUrl}${ServiceUrls.hotel.businessTerms(this.hotelId, this.locale)}`);
    },
    changeLocale(lang) {
      if (isEmpty(lang)) return;
      lang = lang.toLowerCase().substr(0, 2);
      switch (lang) {
        case 'cs':
        case 'de':
        case 'el':
        case 'en':
        case 'es':
        case 'fi':
        case 'fr':
        case 'it':
        case 'ja':
        case 'nl':
        case 'pl':
        case 'pt':
        case 'ro':
        case 'ru':
        case 'sk':
        case 'sl':
        case 'sv':
        case 'zh':
          this.$i18n.locale = lang;
          break;
        default:
          // do nothing
          break;
      }
    },
    gtagTrack(type, arg1, arg2) {
      try {
        if (this.trackingEnabled) {
          this.$gtag.query(type, arg1, arg2);
        }
        // eslint-disable-next-line no-empty
      } catch (ignore) { }
    },
    getOffer(roomRequestId) {
      if (isNil(this.quoteResponse)) return null;
      const selectedOfferIdentifier = this.selectedOffersMap[roomRequestId];
      if (isNil(selectedOfferIdentifier)) return null;
      return find(
        this.quoteResponse.offers,
        (it) => it.ratePlanId === selectedOfferIdentifier.ratePlanId
          && it.roomTypeId === selectedOfferIdentifier.roomTypeId
          && it.roomRequestId === selectedOfferIdentifier.roomRequestId,
      );
    },
  },
};
