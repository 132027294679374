<template>
  <column1>
    <template v-if="!isNil(hotel)">
      <loading v-if="skipping" />
      <h1 class="h1 mb-32">
        {{ $t('homeView.headline') }}
      </h1>
      <availability-calendar :locale="locale"
                             :start-date.sync="arrivalDate"
                             :end-date.sync="departureDate"
                             :hotel="hotel.hotel.encodedHotelId"
                             :unavailable-color="unavailableColor"
                             :unavailable-text-color="unavailableTextColor"
                             :ari="ari"
                             :availability="overallAvailability"
      />
      <div class="button-backdrop" />
      <button-floating v-if="isNil(arrivalDate)" :disabled="true">
        {{ $t('homeView.selectArrivalDate') }}
      </button-floating>
      <button-floating v-else-if="!isNil(arrivalDate) && isNil(departureDate)" :disabled="true">
        {{ $t('homeView.selectDepartureDate') }}
      </button-floating>
      <button-floating v-else @click.stop="onClickNext">
        {{ $t('homeView.next', [formattedDateRange]) }}
      </button-floating>
    </template>
  </column1>
</template>

<script>
import AvailabilityCalendar from '@/components/lib/calendar/AvailabilityCalendar';
import ButtonFloating from '@/components/lib/ButtonFloating/ButtonFloating';
import Column1 from '@/components/lib/Layout/Column1';
import BookingEngineMixin from '@/mixins/BookingEngineMixin';
import { throttle } from 'lodash/function';
import { v4 as uuidv4 } from 'uuid';
import { keyBy } from 'lodash/collection';
import { isEmpty, isNil } from 'lodash/lang';
import DateRangeMixin from '@/mixins/DateRangeMixin';
import Cookies from 'js-cookie';
import fecha from 'fecha';
import { replace } from 'lodash/string';
import shortid from 'shortid';
import Loading from '@/components/lib/Loading';

export default {
  name: 'HomeView',
  components: {
    Column1,
    'availability-calendar': AvailabilityCalendar,
    'button-floating': ButtonFloating,
    'loading': Loading,
  },

  mixins: [BookingEngineMixin, DateRangeMixin],

  props: {
    msg: String,
  },

  data: function () {
    return {
      ari: null,
      arrivalDate: null,
      departureDate: null,
      dateSelectionUuid: uuidv4(),
      throttledUpdateAvailability: throttle(this.updateAvailability, 1000, { leading: true, trailing: false }),
      skipping: false,
      overallAvailability: null,
    };
  },

  computed: {
    maxRoomsAvailable() {
      return this.$store.getters.maxRoomsAvailable;
    },
    unavailableColor() {
      if (!isNil(this.hotel)) {
        switch (this.hotel.hotel.whiteLabel) {
          case 'Rainmaker': return '#eccccd';
          default: return '#f2eeee';
        }
      }
      return '#f2eeee';
    },
    unavailableTextColor() {
      if (!isNil(this.hotel)) {
        switch (this.hotel.hotel.whiteLabel) {
          case 'Rainmaker': return '#a1abb3';
          default: return '#c8d6e0';
        }
      }
      return '#c8d6e0';
    },
  },

  watch: {
    arrivalDate(newVal, oldVal) {
      this.$store.commit('arrivalDate', newVal);
    },
    departureDate(newVal, oldVal) {
      // this.$serviceBus.$emit('infoBox.hide');
      this.dateSelectionUuid = uuidv4();
      if (isNil(newVal)) {
        this.$store.dispatch('roomTypeAvailability', null);
        this.$store.commit('departureDate', null);
      } else {
        this.$store.commit('departureDate', newVal);
        this.throttledUpdateAvailability(this.dateSelectionUuid);
      }
    },
    maxRoomsAvailable: function (newVal) {
      if (newVal === 0) {
        this.$serviceBus.$emit('infoBox.msg', this.$t('availabilityCalendar.infoMsgNoRoomsAvailable'));
      }
    },
  },

  created: function () {
    if (!isEmpty(this.$route.query) && !isEmpty(this.$route.query.arrivalDate) && !isEmpty(this.$route.query.departureDate)) {
      try {
        const arrivalDate = this.$route.query.arrivalDate.includes('-')
          ? fecha.format(fecha.parse(this.$route.query.arrivalDate, 'YYYY-MM-DD'), 'YYYYMMDD')
          : fecha.format(fecha.parse(this.$route.query.arrivalDate, 'YYYYMMDD'), 'YYYYMMDD');

        const departureDate = this.$route.query.departureDate.includes('-')
          ? fecha.format(fecha.parse(this.$route.query.departureDate, 'YYYY-MM-DD'), 'YYYYMMDD')
          : fecha.format(fecha.parse(this.$route.query.departureDate, 'YYYYMMDD'), 'YYYYMMDD');

        this.$store.commit('arrivalDate', arrivalDate);
        this.$store.commit('departureDate', departureDate);
      } catch (e) { /* ignore invalid date errors */ }
    }
    if (!isEmpty(this.$route.query) && !isEmpty(this.$route.query.roomTypeId)) {
      this.$store.commit('preferredRoomTypeId', this.$route.query.roomTypeId);
    }
    if (!isEmpty(this.$route.query) && !isEmpty(this.$route.query.ratePlanId)) {
      this.$store.commit('preferredRatePlanId', this.$route.query.ratePlanId);
    }
    if (!isEmpty(this.$route.query) && !isEmpty(this.$route.query.discountCode)) {
      this.$store.commit('discountCode', this.$route.query.discountCode);
    }
    if (!isEmpty(this.$route.query) && !isEmpty(this.$route.query.lang)) {
      this.changeLocale(this.$route.query.lang);
    }
    if (!isEmpty(this.$route.query) && !isEmpty(this.$route.query.numAdults)) {
      const roomRequests = [];
      for (let i = 1; i <= (this.$route.query.roomCount || 1); i++) {
        roomRequests.push({
          id: shortid.generate(),
          numAdults: parseInt(this.$route.query.numAdults, 10),
          childAgeCategories: [],
        });
        this.$store.dispatch('roomRequests', roomRequests);
      }
    }

    if (!isEmpty(this.$route.query) && !isEmpty(this.$route.query.source)) {
      const hotelId = this.$route.params.encodedHotelId;
      const source = this.$route.query.source;

      switch (source) {
        case 'localuniversal':
          this.$store.commit('sourceName', 'Google Hotel (Search)');
          break;
        case 'mapresults':
          this.$store.commit('sourceName', 'Google Hotel (Map)');
          break;
        default:
          this.$store.commit('sourceName', source);
          break;
      }
    }

    this.arrivalDate = this.$store.getters.arrivalDate;
    this.departureDate = this.$store.getters.departureDate;

    if (!isEmpty(this.$route.query) && !isEmpty(this.$route.query.skip)) {
      if (!isNil(this.arrivalDate) && !isNil(this.departureDate)) {
        if (!isEmpty(this.$route.query.numAdults)) {
          this.updateAvailability(this.dateSelectionUuid).then(() => {
            window.scroll({ top: 0, behavior: 'smooth' });
            this.skipping = true;
            this._quote()
              .then(() => {
                const roomRequestId = this.roomRequests[0].id;
                this.$router.push({ name: 'roomselect', params: { roomRequestId: roomRequestId } });
              })
              .finally(() => {
                this.skipping = false;
              });
          });
        } else {
          this.updateAvailability(this.dateSelectionUuid).then(() => {
            window.scroll({ top: 0, behavior: 'smooth' });
            this.$router.push({ name: 'roomcount' });
          });
        }
      }
    } else {
      this.throttledUpdateAvailability.flush(this.dateSelectionUuid);
    }

    this.updateARI();
    this.updateOverallAvailability();
  },

  mounted: function () {
    this.$nextTick(() => {
      let startElement = document.querySelector('.selected--start');
      if (!isNil(startElement)) startElement = startElement.closest('.month');
      if (!isNil(startElement)) {
        window.scroll({
          // 52 is to adjust for the fixed weekday header
          top: window.scrollY + startElement.getBoundingClientRect().top - 52,
          behavior: 'smooth',
        });
      }
    });
  },

  methods: {
    updateAvailability(dateSelectionUuid) {
      if (dateSelectionUuid !== this.dateSelectionUuid) return Promise.resolve();

      if (isNil(this.arrivalDate)) Promise.resolve();
      if (isNil(this.departureDate)) Promise.resolve();
      if (this.arrivalDate === this.departureDate) return Promise.resolve();
      if (this.departureDate <= this.arrivalDate) return Promise.resolve();

      // to prevent issues when this object is destroyed once the service call finishes
      const $store = this.$store;

      return this.$superagent
        .get(this.serviceUrls.hotel.availability(this.hotelId))
        .query(`locale=${this.locale}&arrivalDate=${this.arrivalDate}&departureDate=${this.departureDate}`)
        .then((res) => {
          const data = keyBy(res.body.data, (it) => it.roomTypeId);
          if (dateSelectionUuid === this.dateSelectionUuid) {
            $store.dispatch('roomTypeAvailability', data);
            this.$serviceBus.$emit('quote'); // update quote in case the availability change affected a previously stored quote
          }
        })
        .catch((err) => {
          if (dateSelectionUuid === this.dateSelectionUuid) $store.dispatch('roomTypeAvailability', null);
          this.$serviceBus.$emit('infoBox.msg', this.$t('errors.failedToQueryAvailability'));
        });
    },
    updateOverallAvailability() {
      return this.$superagent
        .get(this.serviceUrls.hotel.overallAvailability(this.hotelId))
        .query(`locale=${this.locale}`)
        .then((res) => {
          this.overallAvailability = keyBy(res.body, (it) => it.id);
        })
        .catch((err) => {
          this.overallAvailability = null;
        });
    },
    addDays(date, days) {
      const ret = new Date(date);
      ret.setDate(ret.getDate() + days);
      return ret;
    },
    updateARI(dateSelectionUuid) {
      if (this.$store.getters.hotel.details.onlineBookingSettings.extendedAriValidation === true) {
        this.$superagent
          .get(this.serviceUrls.hotel.ari(this.hotelId))
          .query(`locale=${this.locale}`)
          .then((res) => {
            this.ari = res.body.data;
            // if (dateSelectionUuid === this.dateSelectionUuid) $store.dispatch('roomTypeAvailability', data);
          })
          .catch((err) => {
            // if (dateSelectionUuid === this.dateSelectionUuid) $store.dispatch('roomTypeAvailability', null);
            // this.$serviceBus.$emit('infoBox.msg', this.$t('errors.failedToQueryAvailability'));
          });
      } else {
        this.ari = null;
      }
    },
    onClickNext(event) {
      this.throttledUpdateAvailability.flush(this.dateSelectionUuid)
        .then(() => {
          window.scroll({ top: 0, behavior: 'smooth' });
          // this.gtagTrack('event', 'search', { event_category: 'engagement', event_label: this.formattedDateRange });
          this.$router.push({ name: 'roomcount' });
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.button-backdrop {
  @apply fixed bottom-0 left-0 h-128 z-20;
  width: calc(100% + 2 * 64px);
  background: rgb(255,255,255);
  background: linear-gradient(0deg, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%);
  @media (min-width: 768px) {
    @apply fixed left-460;
    width: calc(100% - 460px - 64px + 2 * 64px);
    margin-left: -64px;
    max-width: calc(57.2rem + 2 * 6.4rem);
  }
}
</style>
