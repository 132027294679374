<template>
  <div :class="['bg-grayVeryLight min-h-screen relative w-full mx-auto md:mx-0 p-24 pb-96 md:px-64 md:py-128 xl:border-r border-grayLight max-w-700 col-start-1 md:col-start-2 row-start-2 md:row-start-1', { 'max-w-1400': wide }, { 'py-64': $route.name === 'home' }, styleClass]">
    <button v-if="showBackButton" class="back-link hidden md:grid grid-cols-2 text-primary font-semibold absolute top-80" @click="$serviceBus.$emit('back')">
      <chevron-left-icon size="1.5x" class="" /><span>{{ $t('column1.back') }}</span>
    </button>
    <slot />
  </div>
</template>

<script>
import BookingEngineMixin from '@/mixins/BookingEngineMixin';
import { ChevronLeftIcon } from 'vue-feather-icons';
import Loading from '@/components/lib/Loading';
import baseUrl from '@/lib/urls';
import ServiceUrls from '@/lib/ServiceUrls';

export default {
  name: 'Column1',

  components: {
    ChevronLeftIcon,
    Loading,
  },

  mixins: [BookingEngineMixin],

  props: {
    styleClass: {
      type: String,
      default: '',
    },
  },

  data: function () {
    return {
    };
  },

  computed: {
    showBackButton() {
      return !['home', 'confirmation', 'error', 'disabled', 'payment'].includes(this.$route.name);
    },
  },
  methods: {
  },
};
</script>

<style lang="scss">
</style>

<style lang="scss" scoped>

.back-link {
  grid-template-columns: min-content auto;
  grid-column-gap: 1rem;
}

.legal-links {
  @apply grid;
  grid-template-columns: min-content 1rem min-content;
  white-space: nowrap;

  @media (max-width: 1279px) {
    span {
      display: none;
    }
    margin-top: -10px;
    grid-template-columns: min-content;
    grid-auto-rows: 1fr;
  }
  @media (min-width: 1280px) {

  }
}

</style>
