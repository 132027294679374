// define a mixin object
import { isEmpty, isFunction, isNil, isNumber } from 'lodash/lang';
import { replace, endsWith, trimEnd } from 'lodash/string';
import ServiceUrls from '@/lib/ServiceUrls';
import { keyBy } from 'lodash/collection';

import { throttle } from 'lodash/function';
import baseUrl from '@/lib/urls';
import { bootstrap, setOptions } from 'vue-gtag';
import TimerMixin from '@/mixins/TimerMixin';

export default {
  mixins: [TimerMixin],

  data() {
    return {
      consent: null,
    };
  },

  crated() {
    this.$serviceBus.$on('cookieconsent.run', this.runCookieconsent);
  },

  mounted() {
    this.runCookieconsent();
  },

  beforeDestroy() {
    this.$serviceBus.$off('cookieconsent.run', this.runCookieconsent);
    window.removeEventListener('cc_preferencesSavePressed', this.updateCookieconsent);
    window.removeEventListener('cc_dialogOkPressed', this.updateCookieconsent);
    window.removeEventListener('cc_userChangedConsent', this.updateCookieconsent);
  },

  methods: {
    showCookieconsent() {
      try {
        this.consent.consentBanner.initDialog();
        this.consent.consentBanner.dialog.classList.remove('hidden');
        // eslint-disable-next-line no-empty
      } catch (ignore) { }
    },
    runCookieconsent() {
      // we do net set any cookies apart from google analytics
      if (isEmpty(this.hotel) || isEmpty(this.hotel.details) || isEmpty(this.hotel.details.onlineBookingSettings)) {
        return;
      }

      if (isEmpty(this.hotel.details.onlineBookingSettings.gtagId)
        && isEmpty(this.$store.getters.sourceName)) {
        return;
      }

      // eslint-disable-next-line no-undef
      if (isNil(cookieconsent)) {
        this.setTimeout(this.runCookieconsent, 250);
        return;
      }

      // eslint-disable-next-line no-undef
      this.consent = cookieconsent.run({
        'notice_banner_type': 'simple',
        'consent_type': 'express',
        'palette': 'light',
        'language': this.$i18n.locale,
        'page_load_consent_levels': ['strictly-necessary'],
        'notice_banner_reject_button_hide': false,
        'preferences_center_close_button_hide': false,
        'website_name': 'Online Booking',
      });

      this.updateCookieconsent();

      window.removeEventListener('cc_preferencesSavePressed', this.updateCookieconsent);
      window.removeEventListener('cc_dialogOkPressed', this.updateCookieconsent);
      window.removeEventListener('cc_userChangedConsent', this.updateCookieconsent);

      window.addEventListener('cc_preferencesSavePressed', this.updateCookieconsent);
      window.addEventListener('cc_dialogOkPressed', this.updateCookieconsent);
      window.addEventListener('cc_userChangedConsent', this.updateCookieconsent);
    },
    updateCookieconsent() {
      this.setTimeout(() => {
        if (this.consent && this.consent.userConsent && this.consent.userConsent.acceptedLevels && this.consent.userConsent.acceptedLevels.tracking) {
          this.$store.commit('tracking', true);

          window[`ga-disable-${this.hotel.details.onlineBookingSettings.gtagId}`] = false;
          window['ga-disable-G-V3VFNEYQ2Q'] = false;

          setOptions({
            bootstrap: false,
            appName: 'HotelreservationExpert',
            pageTrackerTemplate(to) {
              return {
                name: to.name,
                path: `/${to.name}`,
                fullPath: `/${to.name}`,
                page_location: `/${to.name}`,
              };
            },
            config: { id: this.hotel.details.onlineBookingSettings.gtagId, params: { anonymize_ip: true, allow_google_signals: this.consent.userConsent.acceptedLevels.targeting === true } },
            includes: [
              { id: 'G-V3VFNEYQ2Q', params: { anonymize_ip: true, allow_google_signals: this.consent.userConsent.acceptedLevels.targeting === true } },
            ],
          });

          bootstrap().then((gtag) => {
            // all done!
          });
        } else {
          this.$store.commit('tracking', false);

          window[`ga-disable-${this.hotel.details.onlineBookingSettings.gtagId}`] = true;
          window['ga-disable-G-V3VFNEYQ2Q'] = true;
        }
      }, 250);
    },
  },
};
