<template>
  <div id="app"
       v-hotkey="hotkeyBinding"
       class="ibe"
       :style="variables"
  >
    <loading v-if="!['error','disabled'].includes($route.name) && isNil(hotel) && !isNil(hotelId)" />
    <div v-else-if="!isNil(hotel)" :key="hotelId">
      <div :class="['grid-wrapper', { 'grid-wrapper--wide': wide }]">
        <div class="relative">
          <router-view name="nav" />
          <info-box />
        </div>
        <page-transition>
          <router-view v-if="initialized" />
        </page-transition>
        <page-transition>
          <router-view v-if="!wide" name="column-2" />
        </page-transition>
        <router-view v-if="!isNil(roomTypeAvailability)" name="modal" />
      </div>
      <modal
        :showing="!isEmpty(modalUrl)"
        :show-close="true"
        :background-close="true"
        @close="modalUrl = null"
      >
        <iframe class="w-full p-0" :src="modalUrl" />
        <div class="w-full absolute bottom-0 py-16 px-24 bg-grayLight cursor-pointer underline text-12 font-semibold" @click="openUrlWindow(modalUrl)">
          {{ $t('modal.openIframeNewWindow') }}
        </div>
      </modal>
    </div>
    <div v-else>
      <div :class="['grid-wrapper', { 'grid-wrapper--wide': wide }]">
        <div class="relative">
          <router-view name="nav" />
        </div>
        <page-transition>
          <router-view />
        </page-transition>
      </div>
    </div>
  </div>
</template>

<script>
import Nav from '@/components/lib/Nav/MainNav';
import InfoBox from '@/components/lib/InfoBox/InfoBox';
import Colors from '@/styles/colors.js';
import BookingEngineMixin from '@/mixins/BookingEngineMixin';
import { isEmpty, isNil } from 'lodash/lang';
import { FadeTransition } from 'vue2-transitions';
import TimerMixin from '@/mixins/TimerMixin';
import Loading from '@/components/lib/Loading';
import regionist from 'regionist/dist/regionist.es.polyfilled';
import Modal from '@/components/lib/Modal/Modal';
import { setOptions, bootstrap } from 'vue-gtag';
import { v4 as uuidv4 } from 'uuid';
import fecha from 'fecha';

export default {
  name: 'App',

  components: {
    navigation: Nav,
    'info-box': InfoBox,
    'page-transition': FadeTransition,
    Loading,
    Modal,
  },

  mixins: [BookingEngineMixin, TimerMixin],

  data: function () {
    return {
      hotkeyBinding: {
        esc: ($event) => { this.$serviceBus.$emit('shortcut.esc'); },
        left: ($event) => { this.$serviceBus.$emit('shortcut.left'); },
        right: ($event) => { this.$serviceBus.$emit('shortcut.right'); },
      },
      hotelSettings: null,
      modalUrl: null,
      consent: null,
    };
  },

  computed: {
    customerSpecificStyleOverrides: function () {
      return {
        '--color-primary': isNil(this.settings) || isEmpty(this.settings.colors.primaryColor) ? Colors['--color-primary'] : this.settings.colors.primaryColor,
      };
    },
    variables: function () {
      return {
        ...Colors,
        ...this.customerSpecificStyleOverrides,
      };
    },
    initialized: function () {
      return this.$store.getters.initialized;
    },
  },

  watch: {
    hotelId: function () {
      this.$store.commit('hotelId', this.$route.params.encodedHotelId);
      this.$store.commit('initialiseStore');
      this.$store.commit('hotel', null);
      this.setTimeout(() => {
        this.loadData();
      }, 500);
    },
    '$i18n.locale': function (newVal) {
      try {
        localStorage.setItem('locale', newVal);
        this.$store.commit('language', newVal);
      } catch (_) { /* ignore */ }
      document.querySelector('html').setAttribute('lang', newVal);
    },
  },

  created() {
    this.$store.commit('hotelId', this.$route.params.encodedHotelId);
    this.$store.commit('serviceBus', this.$serviceBus);
    this.$serviceBus.$on('quote', () => this.quote());
    this.$serviceBus.$on('openUrlModal', this.openUrlModal);
    this.$serviceBus.$on('reloadHotel', this.reloadHotel);
    this.$store.commit('initialiseStore');
    this.setTimeout(() => {
      this.loadData();
    }, 500);
    window.i18n = {
      locale: this.locale,
    };
    this.$store.commit('regionSettings', regionist.guess());
    this.$store.commit('language', this.$i18n.locale);

    try {
      this.$i18n.locale = localStorage.getItem('locale') || 'de';
    } catch (_) { /* ignore */ }

    try {
      document.querySelector('html').setAttribute('lang', localStorage.getItem('locale') || 'de');
    } catch (_) { /* ignore */ }
  },

  beforeDestroy() {
    this.$serviceBus.$off('quote');
    this.$serviceBus.$off('openUrlModal', this.openUrlModal);
    this.$serviceBus.$off('reloadHotel', this.reloadHotel);
  },

  methods: {
    loadData() {
      if (isEmpty(this.hotelId)) {
        setTimeout(this.loadData, 250);
        return;
      }

      this.loadHotel();

      const query = { ...this.$route.query };
      if (query.debug === '1') {
        delete query.debug;
        this.$router.replace({ query });
      }
    },
    openUrlModal(url) {
      this.modalUrl = url;
    },
    openUrlWindow(modalUrl) {
      window.open(modalUrl, '_blank');
      this.modalUrl = null;
    },
    reloadHotel() {
      this.loadHotel(fecha.format(new Date(), 'YYYYMMDDhhmm'));
      this._loadRoomTypeAvailability();
    },
    loadHotel(cacheKey = 'default', cnt = 0) {
      this.$superagent
        .get(this.serviceUrls.hotel.get(this.hotelId))
        .query(`locale=${this.locale}`)
        .query(`cacheKey=${(!isNil(this.$route.query) && this.$route.query.debug === '1') ? uuidv4() : cacheKey}`)
        .then((res) => {
          this.$store.commit('hotel', res.body.data);
          this.$emit('updateHead');
          this.$serviceBus.$emit('cookieconsent.run');
        })
        .catch((err) => {
          if (cnt < 5) {
            this.$rollbar.error('Failed to laod hotel data', err);
            this.loadHotel(cacheKey, ++cnt);
          } else if (cnt === 5) {
            this.setTimeout(() => {
              this.loadHotel(cacheKey, ++cnt);
            }, 500);
          } else {
            this.$serviceBus.$emit('infoBox.msg', this.$t('errors.failedToLoadHotelData'));
            this.$router.push({ name: 'error' });
          }
        });
    },
  },

  head: {
    title: function () {
      if (isNil(this.hotel) || isNil(this.hotel.hotel)) {
        return {
          inner: 'Hotel Reservation Expert',
          separator: null,
          complement: null,
        };
      } else {
        return {
          inner: `${this.hotel.hotel.hotelName}`,
          separator: '|',
          complement: this.hotel.details.city,
        };
      }
    },
    link: function () {
      if (isNil(this.hotel) || isNil(this.hotel.hotel)) {
        return [
          {
            rel: 'icon',
            href: '/favicon.ico',
            id: 'primary',
          },
        ];
      } else {
        return [
          // {
          //   rel: 'icon',
          //   href: this.hotel.hotel.whiteLabel === 'Hotelmeister' ? '/hotelmeister.ico' : '/favicon.ico',
          // },
          {
            id: 'primary',
            rel: 'alternate icon',
            sizes: '96x96',
            type: 'image/png',
            href: `${this.hotel.details.icon || this.hotel.details.logo}?twic=v1/focus=auto/cover=96x96/quality-max=90/output=png`,
          },
        ];
      }
    },
  },

};
</script>

<style lang="scss">
@import '~@/styles/main';
</style>

<style scoped lang="scss">
.ibe {
  //@apply bg-grayVeryLight min-h-screen;
  @apply bg-grayCol2 min-h-screen;
}

.grid-wrapper {
  @apply grid w-full;
  grid-template-columns: 1fr;
  grid-template-rows: auto;

  @media (min-width: 768px) {
    @apply grid w-full;
    grid-template-columns: 40rem 1fr;
    grid-template-rows: auto;
  }
  @media (min-width: 1280px) {
    @apply grid w-full;
    grid-template-columns: 40rem 70rem 1fr;
    grid-template-rows: auto;

    &--wide {
      grid-template-columns: 40rem 1fr;
    }
  }
}
</style>

<style lang="scss">
  .tooltip {
    max-width: 300px!important;
  }

  .modal {
    iframe {
      height: calc(100% - 5.0rem);
    }
  }

</style>
