<template>
  <div :class="`month first-day-of-week--${firstDayOfWeek}`" :data-month="yearMonth">
    <div class="month-name">
      {{ label }}
    </div>
    <!--
    <div class="weekdays">
      <div v-for="weekday in weekdays" class="weekday">
        {{ weekday }}
      </div>
    </div>
    -->
    <template v-for="i in numberDays">
      <div :key="i"
           v-tooltip="{ content: dateTooltips[i]}"
           :class="dayClasses(i)"
           :style="dayStyle(i)"
           class="day"
           @mouseover="hoverDate($event, i)"
           @click="ontouchend($event, i)"
      >
        <span class="day--text">{{ i }}</span>

        <span class="day--price">
          <template v-if="!isNil(getDateAri(i))">
            <template v-if="getDateAri(i).minAmount > 0">{{ getDateAri(i).minAmount.toFixed(0) }}&nbsp;{{ hotel.details.currency }}</template>
            <template v-else>&nbsp;</template>
          </template>
          <template v-else>&nbsp;</template>
        </span>
      </div>
    </template>
  </div>
</template>

<script>
import fecha from 'fecha';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { endsWith, replace, trimEnd } from 'lodash/string';

export default {
  name: 'Month',

  filters: {

    toCurrency: function (value) {
      let ret = `${(isNil(value) ? 0 : value).toLocaleString(
        window.i18n.locale,
        {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        },
      )}`;

      if (endsWith(ret, '00')) {
        ret = trimEnd(ret, '0');
        ret += '-';
      }

      return ret;
    },
    toCurrencyDigits: function (value) {
      const ret = `${(isNil(value) ? 0 : value).toLocaleString(
        window.i18n.locale,
        {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        },
      )}`;

      return ret;
    },
  },

  props: {
    label: {
      type: String,
      default: '',
    },
    firstDayOfWeek: {
      type: String,
      default: 'mo',
    },
    weekdays: {
      type: Array,
    },
    firstDayOfMonth: {
      type: Date,
      default: () => new Date(),
    },
    selectedDates: {
      type: Array,
      default: () => [],
    },
    selectionActive: {
      type: Boolean,
      default: false,
    },
    minDate: {
      type: String,
      default: null,
    },
    maxDate: {
      type: String,
      default: null,
    },
    dateColors: {
      type: Object,
      default: () => ({}),
    },
    ari: {
      type: Object,
      default: null,
    },
    availability: {
      type: Object,
      default: null,
    },
  },

  data: function () {
    return {
      todayFormated: fecha.format(this.$parent.today, 'YYYY-MM-DD'),
      selectTimer: null,
    };
  },

  computed: {
    dateTooltips: function () {
      const ret = {};
      for (let i = 1; i <= this.numberDays; i++) {
        ret[i] = this.dateTooltip(i);
      }
      return ret;
    },
    hotel: function () {
      return this.$store.getters.hotel;
    },
    currency: function () {
      if (isNil(this.hotel) || isNil(this.hotel.details)) return '';
      return this.hotel.details.currency;
    },
    locale: function () {
      return (this.$i18n.locale || 'de').substr(0, 2).toLowerCase();
    },
    yearMonth: function () {
      return isNil(this.firstDayOfMonth) ? null : fecha.format(this.firstDayOfMonth, 'YYYY-MM-DD');
    },
    numberDays: function () {
      return isNil(this.firstDayOfMonth) ? 30 : new Date(this.firstDayOfMonth.getYear() + 1900, this.firstDayOfMonth.getMonth() + 1, 0).getDate();
    },
    firstDayOfMonthClass: function () {
      if (this.firstDayOfMonth == null) return null;

      const prefix = '';

      // eslint-disable-next-line default-case
      switch (this.firstDayOfMonth.getDay()) {
        case 1: return `${prefix}mo`;
        case 2: return `${prefix}tu`;
        case 3: return `${prefix}we`;
        case 4: return `${prefix}th`;
        case 5: return `${prefix}fr`;
        case 6: return `${prefix}sa`;
        case 0: return `${prefix}su`;
      }

      return this.firstDayOfMonth.getDay();
    },
    isoMonthPrefix: function () {
      if (this.firstDayOfMonth == null) return null;

      return fecha.format(this.firstDayOfMonth, 'YYYY-MM-');
    },
    todayMidnight() {
      const date = new Date(this.$parent.today);
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      date.setMilliseconds(0);

      return date;
    },
  },

  methods: {
    formatDate: function (i) {
      const dateString = `${this.isoMonthPrefix}${this.pad(i, 2)}`;
      const date = fecha.parse(dateString, 'YYYY-MM-DD');
      const format = {
        weekday: 'long',
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      };

      return new Intl.DateTimeFormat(this.locale, format).format(date);
    },
    getDateAri: function (i) {
      if (isNil(this.ari)) return null;
      if (isNil(this.ari.byDate)) return null;

      const dateString = replace(`${this.isoMonthPrefix}${this.pad(i, 2)}`, new RegExp('-', 'g'), '');
      return this.ari.byDate[dateString];
    },
    hoverDate: function ($event, i) {
      $event.preventDefault();
      let month = null;
      if (!isEmpty($event.changedTouches)) {
        let elem = document.elementFromPoint($event.changedTouches[0].clientX, $event.changedTouches[0].clientY);
        if (isNil(elem) || !elem.classList.contains('day')) {
          elem = elem.closest('.day');
        }
        if (isNil(elem) || !elem.classList.contains('day')) {
          i = null;
        } else if (!isNil(elem)) {
          i = elem.textContent.trim();
          month = fecha.parse(elem.closest('.month').getAttribute('data-month'), 'YYYY-MM-DD');
        }
      }
      if (this.selectionActive) {
        this.$emit('hoverdate', {
          day: i,
          month: month,
        });
      }
    },
    ontouchend($event, i) {
      const classes = this.dayClasses(i);
      if (this.selectionActive) {
        if (classes.includes('past')) {
          this.$emit('clickpast', $event);
        } else if (classes.includes('restriction-departure-closed')) {
          this.$serviceBus.$emit('infoBox.msg', this.$t('availabilityCalendar.infoMsgNoDeparture', [this.formatDate(i)]));
        } else {
          // this.$serviceBus.$emit('infoBox.hide');
          this.selectEndDate($event, i);
        }
      } else if (classes.includes('restriction-arrival-closed') || classes.includes('past')) {
        this.$serviceBus.$emit('infoBox.msg', this.$t('availabilityCalendar.infoMsgNoArrival', [this.formatDate(i)]));
      } else {
        // this.$serviceBus.$emit('infoBox.hide');
        this.selectStartDate($event, i);
        if (this.$store.getters.hotel.details.onlineBookingSettings.extendedAriValidation === true && !isNil(this.ari) && !isNil(this.ari.byDate)) {
          const ari = this.getDateAri(i);
          if (!isNil(ari) && !isNil(ari.fplos)) {
            const minlos = Math.max(1, (ari.fplos.indexOf(true) + 1));
            if (minlos > 1) {
              this.$serviceBus.$emit('infoBox.msg', this.$t('availabilityCalendar.infoMsgMinLOS', [minlos]));
            }
          }
        }
      }
    },
    selectStartDate: function ($event, i) {
      if (this.selectionActive) return;
      $event.preventDefault();
      this.$emit('selectstartdate', {
        day: i,
        month: this.firstDayOfMonth,
      });
      clearTimeout(this.selectTimer);
      this.selectTimer = setTimeout(() => {
        this.selectTimer = null;
      }, 250);
    },
    selectEndDate: function ($event, i) {
      if (!this.selectionActive) return;
      if (!isNil(this.selectTimer)) return;
      $event.preventDefault();
      let month = null;
      if (!isEmpty($event.changedTouches)) {
        let elem = document.elementFromPoint($event.changedTouches[0].clientX, $event.changedTouches[0].clientY);
        if (isNil(elem) || !elem.classList.contains('day')) {
          elem = elem.closest('.day');
        }
        if (isNil(elem) || !elem.classList.contains('day')) {
          i = null;
        } else if (!isNil(elem)) {
          i = elem.textContent.trim();
          month = fecha.parse(elem.closest('.month').getAttribute('data-month'), 'YYYY-MM-DD');
        }
      }
      this.$emit('selectenddate', {
        day: i,
        month: isNil(month) ? this.firstDayOfMonth : month,
      });
    },
    isNil(val) {
      return (val === null || typeof val === 'undefined');
    },
    dayStyle(i) {
      const dayString = `${this.isoMonthPrefix}${this.pad(i, 2)}`;
      const color = this.dateColors[dayString];

      if (isEmpty(color)) return {};

      if (!isNil(this.minDate) && dayString < this.minDate) {
        return {};
      }

      if (!isNil(this.maxDate) && dayString > this.maxDate) {
        return {};
      }

      return {
        'background-color': `rgba(${this.toRGB(color)}, 0.6)`,
      };
    },
    dayClasses(i) {
      const ret = [];
      if (i === 1) {
        ret.push(this.firstDayOfMonthClass);
      }

      const dayString = `${this.isoMonthPrefix}${this.pad(i, 2)}`;

      if (this.hotel.details.onlineBookingSettings.displayMinRate === true) {
        ret.push('has-price');
      }

      if (dayString === this.todayFormated) {
        ret.push('today');
      }

      if (!isNil(this.minDate) && dayString < this.minDate) {
        ret.push('past');
        return ret;
      }

      if (!isNil(this.maxDate) && dayString > this.maxDate) {
        ret.push('past');
        return ret;
      }

      /*
      if (fecha.parse(dayString, 'YYYY-MM-DD') < this.todayMidnight) {
        ret.push('past');
      }
      */

      if (this.$store.getters.hotel.details.onlineBookingSettings.extendedAriValidation === true && !isNil(this.ari) && !isNil(this.ari.byDate)) {
        const ari = this.getDateAri(i);

        if (!isNil(ari)) {
          if (ari.arrival === false) {
            ret.push('restriction-arrival-closed');
          }
          if (ari.departure === false) {
            ret.push('restriction-departure-closed');
          }
        }
      }

      if (this.$parent.disabledDates.includes(dayString) && !this.$parent.departureOnlyDates.includes(dayString)) {
        ret.push('disabled');
        ret.push('restriction-arrival-closed');
        ret.push('restriction-departure-closed');
      }

      if (this.$parent.noArrivalDates.includes(dayString)) {
        ret.push('restriction-arrival-closed');
      }

      if (this.$parent.arrivalOnlyDates.includes(dayString) && dayString !== this.todayFormated) {
        ret.push('arrival-only');
        ret.push('restriction-departure-closed');
      }

      if (this.$parent.departureOnlyDates.includes(dayString)) {
        ret.push('departure-only');
        ret.push('restriction-arrival-closed');

        if (!this.selectionActive) {
          ret.push('no-click');
        }
      }

      if (!isEmpty(this.selectedDates)) {
        if (this.selectedDates.indexOf(dayString) === 0) {
          ret.push('selected--start');
        }

        if ((this.selectedDates.indexOf(dayString) + 1) === this.selectedDates.length) {
          if (this.selectionActive) {
            ret.push('selected--end');
            // ret.push('selected--end--unconfirmed');
          } else {
            ret.push('selected--end');
          }
        }

        if (this.selectedDates.indexOf(dayString) >= 0) {
          ret.push('selected');
        }
      }

      return ret;
    },
    addDays(date, days) {
      const ret = new Date(date);
      ret.setDate(ret.getDate() + days);
      return ret;
    },
    pad(num, size) {
      let s = `${num}`;
      while (s.length < size) s = `0${s}`;
      return s;
    },
    toRGB(hexColorCode) {
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexColorCode);
      return result ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}` : '';
    },
    dateTooltip(i) {
      if (this.selectedDates.length > 1 && this.selectionActive) return null;
      const dateAri = this.getDateAri(i);
      if (isNil(dateAri)) return null;
      let ret = '';

      const dayString = `${this.isoMonthPrefix}${this.pad(i, 2)}`;
      if (this.$parent.arrivalOnlyDates.includes(dayString)) ret += `${this.$t('roomSelect.arrivalOnly')}<br/>`;
      else if (this.$parent.departureOnlyDates.includes(dayString)) ret += `${this.$t('roomSelect.departureOnly')}<br/>`;
      else if (this.$parent.disabledDates.includes(dayString)) ret += `${this.$t('roomSelect.noAvailability')}<br/>`;
      // if (dateAri.bookable === false && dateAri.departure === false) return null;

      // const dayString = `${this.isoMonthPrefix}${this.pad(i, 2)}`;

      if (dateAri.minAmount > 0 && dateAri.bookable === true) ret += `${this.$t('roomSelect.priceFrom')} ${this.currency}&nbsp;${this.$options.filters.toCurrencyDigits(dateAri.minAmount)} ${this.$t('roomSelect.pricePerNight')}<br/>`;
      if (dateAri.fplos.indexOf(true) >= 1 && dateAri.bookable === true) ret += this.$t('availabilityCalendar.infoMsgMinLOS', [(dateAri.fplos.indexOf(true) + 1)]);
      // if (this.$parent.arrivalOnlyDates.includes(dayString)) ret += 'Nur Anreise<br/>';
      // if (this.$parent.departureOnlyDates.includes(dayString)) ret += 'Nur Abreise<br/>';

      return ret;
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
