<template>
  <div :class="`month first-day-of-week--${firstDayOfWeek} month--weekdays`">
    <div class="weekdays" data-margin-top="-1">
      <div v-for="weekday in weekdays" class="weekday">
        {{ weekday }}
      </div>
    </div>
  </div>
</template>

<script>
import fecha from 'fecha';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import Sticky from 'sticky-js';

export default {
  name: 'Weekdays',

  props: {
    label: {
      type: String,
      default: '',
    },
    firstDayOfWeek: {
      type: String,
      default: 'mo',
    },
    weekdays: {
      type: Array,
    },
  },

  data: function () {
    return {
      sticky: null,
    };
  },

  mounted() {
    this.sticky = new Sticky('.month--weekdays .weekdays');
  },

  beforeDestroy() {
    if (!isNil(this.sticky)) {
      this.sticky.destroy();
    }
  },
};
</script>

<style lang="scss" scoped>

</style>
