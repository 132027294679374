import { isNil } from 'lodash/lang';

export default {
  data() {
    return {
      _timeoutIds: [],
      _intervalIds: [],
    };
  },

  methods: {
    /**
     * Set a single run timer
     *
     * @param {Function} callback
     * @param {Number} timeout
     *
     * @returns {Number}
     */
    setTimeout(callback, timeout) {
      if (!this.$data._timeoutIds) {
        return null;
      }

      const timer = setTimeout(() => {
        this.clearTimeout(timer);

        callback();
      }, timeout);

      this.$data._timeoutIds.push(timer);

      return timer;
    },

    /**
     * Set an interval
     *
     * @param {Function} callback
     * @param {Number} timeout
     *
     * @returns {Number}
     */
    setInterval(callback, timeout) {
      if (!this.$data._intervalIds) {
        return null;
      }

      const timer = setInterval(callback, timeout);

      this.$data._intervalIds.push(timer);

      return timer;
    },

    /**
     * Remove a timer from internal tracking
     *
     * @private
     *
     * @param {Number} timerId
     */
    _removeTimeout(timerId) {
      if (!isNil(this.$data) && !isNil(this.$data._intervalIds)) {
        this.$data._timeoutIds = this.$data._timeoutIds.filter((id) => id !== timerId);
      }
    },

    /**
     * Remove a timer from internal tracking
     *
     * @private
     *
     * @param {Number} timerId
     */
    _removeInterval(timerId) {
      if (!isNil(this.$data) && !isNil(this.$data._intervalIds)) {
        this.$data._intervalIds = this.$data._intervalIds.filter((id) => id !== timerId);
      }
    },

    /**
     * Clear a timeout
     *
     * @param {Number} timerId
     *
     * @returns {*}
     */
    clearTimeout(timerId) {
      this._removeTimeout(timerId);

      return clearTimeout(timerId);
    },

    /**
     * Clear an interval
     *
     * @param {Number} timerId
     *
     * @returns {*}
     */
    clearInterval(timerId) {
      this._removeInterval(timerId);

      return clearInterval(timerId);
    },
  },

  beforeDestroy() {
    if (this.$data._timeoutIds) {
      this.$data._timeoutIds.forEach((id) => {
        this.clearTimeout(id);
      });

      this.$data._timeoutIds = [];
    }
    if (this.$data._intervalIds) {
      this.$data._intervalIds.forEach((id) => {
        this.clearInterval(id);
      });

      this.$data._intervalIds = [];
    }
  },
};
