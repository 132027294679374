<template>
  <div class="md:fixed left-0 top-0 md:w-400 bg-white nav">
    <nav class="relative grid md:block justify-between items-center h-64 md:h-screen px-16 md:px-48 md:pt-128 overflow-y-auto">
    </nav>
  </div>
</template>

<script>

export default {
  name: 'EmptyNav',
};
</script>
