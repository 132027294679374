import Vue from 'vue';
import Vuex from 'vuex';
import { cloneDeep, isEmpty, isNil, isString } from 'lodash/lang';
import { findIndex, head, last, takeRight, uniq } from 'lodash/array';
import regionist from 'regionist/dist/regionist.es.polyfilled';
import fecha from 'fecha';
import { find, groupBy, keyBy, map, size } from 'lodash/collection';
import { maxBy, minBy, sum, sumBy } from 'lodash/math';
import shortid from 'shortid';
import Cookies from 'js-cookie';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    languages: ['de', 'en', 'es', 'fr', 'it', 'nl', 'pl', 'pt', 'ru', 'cs', 'el', 'fi', 'hu', 'ja', 'ro', 'sk', 'sl', 'sv', 'zh'],
    hotel: null,
    language: null,
    hotelId: null,
    serviceBus: null,
    roomTypeAvailability: null,
    maxRoomsAvailable: 4,
    quoteRequest: {
      arrivalDate: null,
      departureDate: null,
      roomRequests: [],
      discountCode: null,
    },
    quoteResponse: null,
    selectedOffers: [],
    selectedShoppingCartItems: [],
    guestData: null,
    regionSettings: {},
    contactData: null,
    initialized: false,
    tracking: false,
    preferredRoomTypeId: null,
    preferredRatePlanId: null,
    availabilityByMaxOccupancy: null,
    enhancedRoomRequests: [],
    sourceName: null,
  },
  mutations: {
    initialiseStore(state) {
      const loadValue = function (value) {
        try {
          const val = localStorage.getItem(value);
          const key = value.indexOf(`${state.hotelId}__`) === 0 ? value.substr(`${state.hotelId}__`.length) : value;
          switch (key) {
            case 'arrivalDate':
            case 'departureDate':
            case 'discountCode':
              if (!isEmpty(val)) state.quoteRequest[key] = val;
              break;
            case 'roomRequests':
              if (!isEmpty(val)) state.quoteRequest[key] = JSON.parse(val);
              break;
            default:
              if (!isNil(val)) state[key] = JSON.parse(val);
              break;
          }
        } catch (_) { /* ignore */ }
      };

      state.roomTypeAvailability = null;

      loadValue(`${state.hotelId}__arrivalDate`);
      loadValue(`${state.hotelId}__departureDate`);
      loadValue(`${state.hotelId}__discountCode`);
      state.serviceBus.$emit('quote');

      const today = new Date();
      today.setHours(0, 0, 0, 0);

      if (!isEmpty(state.quoteRequest.arrivalDate) && fecha.parse(state.quoteRequest.arrivalDate, 'YYYYMMDD') >= today) {
        loadValue(`${state.hotelId}__roomRequests`);
        loadValue(`${state.hotelId}__selectedOffers`);
        loadValue(`${state.hotelId}__selectedShoppingCartItems`);
      } else {
        state.quoteRequest.arrivalDate = null;
        state.quoteRequest.departureDate = null;
      }

      loadValue('contactData');

      state.initialized = true;
    },
    language(state, language) {
      state.language = language;
    },
    hotelId(state, hotelId) {
      state.hotelId = hotelId;
    },
    hotel(state, hotel) {
      if (!isEmpty(state.hotelId) && !isEmpty(hotel)) {
        try {
          localStorage.setItem('lastHotelId', state.hotelId);
        } catch (_) { /* ignore */ }
      }
      const tmp = cloneDeep(hotel);

      try {
        if (!isEmpty(tmp)) {
          const fillMissingTranslations = (type, field) => {
            if (!isEmpty(tmp[type])) {
              tmp[type].forEach((it) => {
                state.languages.forEach((lang) => {
                  if (!isEmpty(it[field]) && isEmpty(it[field][lang])) it[field][lang] = it[field][tmp.details.fallbackLanguage] || it[field][tmp.details.primaryLanguage] || '';
                });
              });
            }
          };

          fillMissingTranslations('roomTypes', 'name');
          fillMissingTranslations('roomTypes', 'description');
          fillMissingTranslations('ratePlans', 'name');
          fillMissingTranslations('ratePlans', 'description');
          fillMissingTranslations('ratePlans', 'longDescription');
          fillMissingTranslations('inHouseServices', 'name');
          fillMissingTranslations('inHouseServices', 'description');
          fillMissingTranslations('ageCategories', 'name');
        }
      } catch (ignore) { /* ignore */ }
      state.hotel = tmp;

      try {
        const whiteLabel = state.hotel.hotel.whiteLabel;
        if (!isEmpty(whiteLabel)) {
          document.getElementById('script__output').innerHTML = '';
          document.querySelectorAll(`script[type="text/plain"][data-whitelabel="${whiteLabel}"]`).forEach((it) => {
            const s = document.createElement('script');
            s.type = 'text/javascript';
            if (!isNil(it.getAttribute('src'))) {
              s.src = it.getAttribute('src');
              s.innerHTML = null;
            } else {
              s.innerHTML = it.innerHTML;
            }
            document.getElementById('script__output').appendChild(s);
          });
        }
      } catch (e) { /* ignore */ }
    },
    tracking(state, tracking) {
      state.tracking = tracking;

      if (!tracking) {
        Cookies.remove(`${state.hotelId}_source`);
      } else if (!isEmpty(state.sourceName)) {
        Cookies.set(`${state.hotelId}_source`, state.sourceName, { expires: 30 });
      }
    },
    contactData(state, contactData) {
      state.contactData = contactData;

      if (contactData.saveContactData !== true || isNil(contactData)) {
        try {
          localStorage.removeItem('contactData');
        } catch (_) { /* ignore */ }
      } else {
        try {
          localStorage.setItem('contactData', JSON.stringify(contactData));
        } catch (_) { /* ignore */ }
      }
    },
    regionSettings(state, regionSettings) {
      state.regionist = regionist;
    },
    serviceBus(state, serviceBus) {
      state.serviceBus = serviceBus;
    },
    preferredRoomTypeId(state, preferredRoomTypeId) {
      state.preferredRoomTypeId = preferredRoomTypeId;
    },
    preferredRatePlanId(state, preferredRatePlanId) {
      state.preferredRatePlanId = preferredRatePlanId;
    },
    sourceName(state, sourceName) {
      if (!isNil(sourceName) && !isString(sourceName)) return; // we had cases where multiple source names were passed, which resulted in sourceName being an array- in this case we just ignore it
      state.sourceName = sourceName;

      if (state.tracking) {
        Cookies.set(`${state.hotelId}_source`, sourceName, { expires: 30 });
      }
    },
    discountCode(state, discountCode) {
      state.quoteRequest.discountCode = isEmpty(discountCode) ? null : discountCode.toUpperCase();
      state.serviceBus.$emit('quote');
      if (isEmpty(discountCode)) {
        try {
          localStorage.removeItem(`${state.hotelId}__discountCode`);
        } catch (_) { /* ignore */ }
      } else {
        try {
          localStorage.setItem(`${state.hotelId}__discountCode`, discountCode);
        } catch (_) { /* ignore */ }
      }
    },
    roomTypeAvailability(state, roomTypeAvailability) {
      state.roomTypeAvailability = roomTypeAvailability;

      if (!isNil(roomTypeAvailability)) {
        state.maxRoomsAvailable = sum(map(roomTypeAvailability, (it) => Math.max(0, it.countAvailable)));
      } else {
        state.maxRoomsAvailable = 4;
      }

      // state.serviceBus.$emit('quote');
    },
    arrivalDate(state, arrivalDate) {
      state.quoteRequest.arrivalDate = arrivalDate;
      state.roomTypeAvailability = null;
      state.serviceBus.$emit('quote');
      if (isNil(arrivalDate)) {
        try {
          localStorage.removeItem(`${state.hotelId}__arrivalDate`);
        } catch (_) { /* ignore */ }
      } else {
        try {
          localStorage.setItem(`${state.hotelId}__arrivalDate`, arrivalDate);
        } catch (_) { /* ignore */ }
      }
    },
    departureDate(state, departureDate) {
      state.quoteRequest.departureDate = departureDate;
      state.roomTypeAvailability = null;
      state.serviceBus.$emit('quote');
      if (isNil(departureDate)) {
        try {
          localStorage.removeItem(`${state.hotelId}__departureDate`);
        } catch (_) { /* ignore */ }
      } else {
        try {
          localStorage.setItem(`${state.hotelId}__departureDate`, departureDate);
        } catch (_) { /* ignore */ }
      }
    },
    roomRequests(state, roomRequests) {
      state.quoteRequest.roomRequests = roomRequests;
      state.serviceBus.$emit('quote');
      if (isNil(roomRequests)) {
        try {
          localStorage.removeItem(`${state.hotelId}__roomRequests`);
        } catch (_) { /* ignore */ }
      } else {
        try {
          localStorage.setItem(`${state.hotelId}__roomRequests`, JSON.stringify(roomRequests));
        } catch (_) { /* ignore */ }
      }
    },
    addRoomRequest(state, roomRequest) {
      state.quoteRequest.roomRequests.push(roomRequest);
      state.serviceBus.$emit('quote');
      if (isNil(state.roomRequests)) {
        try {
          localStorage.removeItem(`${state.hotelId}__roomRequests`);
        } catch (_) { /* ignore */ }
      } else {
        try {
          localStorage.setItem(`${state.hotelId}__roomRequests`, JSON.stringify(state.roomRequests));
        } catch (_) { /* ignore */ }
      }
    },
    removeRoomRequest(state, roomRequest) {
      const index = findIndex(state.quoteRequest.roomRequests, (it) => it.id === roomRequest.id);
      if (index >= 0) {
        state.quoteRequest.roomRequests.splice(index, 1);
      }

      const selectedOfferIndex = findIndex(state.selectedOffers, (it) => it.roomRequestId === roomRequest.id);
      if (selectedOfferIndex >= 0) {
        state.selectedOffers.splice(selectedOfferIndex, 1);
      }

      state.serviceBus.$emit('quote');
      if (isNil(state.roomRequests)) {
        try {
          localStorage.removeItem(`${state.hotelId}__roomRequests`);
        } catch (_) { /* ignore */ }
      } else {
        try {
          localStorage.setItem(`${state.hotelId}__roomRequests`, JSON.stringify(state.roomRequests));
        } catch (_) { /* ignore */ }
      }
    },
    quoteResponse(state, quoteResponse) {
      state.quoteResponse = quoteResponse;
    },
    unselectOffer(state, roomRequestId) {
      {
        const selectedOfferIndex = findIndex(state.selectedOffers, (it) => it.roomRequestId === roomRequestId);
        if (selectedOfferIndex >= 0) {
          state.selectedOffers.splice(selectedOfferIndex, 1);
        }
        if (isNil(state.selectedOffers)) {
          try {
            localStorage.removeItem(`${state.hotelId}__selectedOffers`);
          } catch (_) { /* ignore */
          }
        } else {
          try {
            localStorage.setItem(`${state.hotelId}__selectedOffers`, JSON.stringify(state.selectedOffers));
          } catch (_) { /* ignore */
          }
        }
      }
      {
        const selectedOfferIndex = findIndex(state.selectedShoppingCartItems, (it) => it.roomRequestId === roomRequestId);
        if (selectedOfferIndex >= 0) {
          state.selectedShoppingCartItems.splice(selectedOfferIndex, 1);
        }
        if (isNil(state.selectedShoppingCartItems)) {
          try {
            localStorage.removeItem(`${state.hotelId}__selectedShoppingCartItems`);
          } catch (_) { /* ignore */ }
        } else {
          try {
            localStorage.setItem(`${state.hotelId}__selectedShoppingCartItems`, JSON.stringify(state.selectedOffers));
          } catch (_) { /* ignore */ }
        }
      }
    },
    selectOffer(state, selection) {
      if (isNil(state.selectedOffers)) {
        state.selectedOffers = [];
      }

      if (isNil(selection)) return;

      const index = findIndex(state.selectedOffers, (it) => it.roomRequestId === selection.roomRequestId);
      if (index >= 0) {
        state.selectedOffers.splice(index, 1);
      }

      if (!isNil(selection.roomTypeId) && !isNil(selection.ratePlanId)) {
        state.selectedOffers.push(cloneDeep(selection));
      }
      if (isNil(state.selectedOffers)) {
        try {
          localStorage.removeItem(`${state.hotelId}__selectedOffers`);
        } catch (_) { /* ignore */ }
      } else {
        try {
          localStorage.setItem(`${state.hotelId}__selectedOffers`, JSON.stringify(state.selectedOffers));
        } catch (_) { /* ignore */ }
      }
    },
    updateShoppingCartItems(state, update) {
      if (isNil(update)) return;
      if (isNil(update.roomRequestId)) return;
      if (isNil(state.selectedShoppingCartItems)) state.selectedShoppingCartItems = [];

      const index = findIndex(state.selectedShoppingCartItems, (it) => it.roomRequestId === update.roomRequestId);

      let selectedOffer;
      if (index >= 0) {
        selectedOffer = state.selectedShoppingCartItems[index];
      } else {
        selectedOffer = update;
        state.selectedShoppingCartItems.push(selectedOffer);
      }

      if (isNil(selectedOffer)) return;

      if (isNil(update.shoppingCartItems)) {
        selectedOffer.shoppingCartItems = [];
        return;
      }

      selectedOffer.shoppingCartItems = update.shoppingCartItems;

      if (isNil(state.selectedShoppingCartItems)) {
        try {
          localStorage.removeItem(`${state.hotelId}__selectedShoppingCartItems`);
        } catch (_) { /* ignore */ }
      } else {
        try {
          localStorage.setItem(`${state.hotelId}__selectedShoppingCartItems`, JSON.stringify(state.selectedShoppingCartItems));
        } catch (_) { /* ignore */ }
      }
    },
  },
  getters: {
    logData: (state) => ({
      // hotel: state.hotel,
      hotelId: state.hotelId,
      language: state.language,
      roomTypeAvailability: state.roomTypeAvailability,
      maxRoomsAvailable: state.maxRoomsAvailable,
      quoteRequest: state.quoteRequest,
      quoteResponse: state.quoteResponse,
      selectedOffers: state.selectedOffers,
      selectedShoppingCartItems: state.selectedShoppingCartItems,
      guestData: state.guestData,
      contactData: state.contactData,
      initialized: state.initialized,
      tracking: state.tracking,
      preferredRoomTypeId: state.preferredRoomTypeId,
      preferredRatePlanId: state.preferredRatePlanId,
      availabilityByMaxOccupancy: state.availabilityByMaxOccupancy,
      enhancedRoomRequests: state.enhancedRoomRequests,
      sourceName: state.sourceName,
      discountCode: state.quoteRequest.discountCode,
    }),
    selectedOffers: (state) => (isNil(state.selectedOffers) ? [] : cloneDeep(state.selectedOffers)),
    selectedShoppingCartItems: (state) => (isNil(state.selectedShoppingCartItems) ? [] : cloneDeep(state.selectedShoppingCartItems)),
    hotel: (state) => state.hotel,
    hotelId: (state) => state.hotelId,
    contactData: (state) => state.contactData,
    sourceName: (state) => state.sourceName,
    discountCode: (state) => state.quoteRequest.discountCode,
    regionSettings: (state) => state.regionist,
    roomTypeAvailability: (state) => state.roomTypeAvailability,
    maxRoomsAvailable: (state) => state.maxRoomsAvailable,
    quoteRequest: (state) => {
      const ret = cloneDeep(state.quoteRequest);
      ret.roomRequests.forEach((it) => {
        it.numChildren = it.childAgeCategories.length;
        it.childAge = it.childAgeCategories.map((acId) => ({ ageCategoryId: acId }));
      });

      return ret;
    },
    roomRequests: (state) => state.quoteRequest.roomRequests,
    quoteResponse: (state) => state.quoteResponse,
    arrivalDate: (state) => state.quoteRequest.arrivalDate,
    departureDate: (state) => state.quoteRequest.departureDate,
    initialized: (state) => state.initialized,
    tracking: (state) => state.tracking,
    preferredRoomTypeId: (state) => state.preferredRoomTypeId,
    preferredRatePlanId: (state) => state.preferredRatePlanId,
    availabilityByMaxOccupancy: (state) => state.availabilityByMaxOccupancy,
    enhancedRoomRequests: (state) => state.enhancedRoomRequests,
    language: (state) => state.language,
  },
  actions: {
    roomTypeAvailability(context, roomTypeAvailability) {
      context.commit('roomTypeAvailability', roomTypeAvailability);
      context.dispatch('updateUserSelections');
    },
    addRoomRequest(context, roomRequest) {
      context.commit('addRoomRequest', roomRequest);
      context.dispatch('updateUserSelections');
    },
    removeRoomRequest(context, roomRequest) {
      context.commit('removeRoomRequest', roomRequest);
      context.dispatch('updateUserSelections');
    },
    selectOffer(context, selection) {
      context.commit('selectOffer', selection);
    },
    updateShoppingCartItems(context, selection) {
      context.commit('updateShoppingCartItems', selection);
    },
    roomRequests(context, roomRequests) {
      context.commit('roomRequests', roomRequests);
      context.dispatch('updateUserSelections');
    },
    unselectOffer(context, roomRequestId) {
      context.commit('unselectOffer', roomRequestId);
    },
    quoteResponse(context, quoteResponse) {
      try {
        const state = context.state;

        if (!isNil(quoteResponse)) {
          const roomTypeIds = uniq(map(quoteResponse.offers, 'roomTypeId'));
          const ratePlanIds = uniq(map(quoteResponse.offers, 'ratePlanId'));

          const hotel = state.hotel;
          const hotelRoomTypeIds = uniq(map(hotel.roomTypes, 'id'));
          const hotelRatePlanIds = uniq(map(hotel.ratePlans, 'id'));

          if (!roomTypeIds.every((it) => hotelRoomTypeIds.includes(it)) || !ratePlanIds.every((it) => hotelRatePlanIds.includes(it))) {
            state.serviceBus.$emit('reloadHotel');
          }
        }

        context.commit('quoteResponse', quoteResponse);
        context.dispatch('update123CompareMe');
        context.dispatch('updateUserSelections');
      } catch (e) {
        context.commit('quoteResponse', quoteResponse);
        context.dispatch('update123CompareMe');
        context.dispatch('updateUserSelections');
        throw e;
      }
    },
    updateShoppingCartCounts(context) {
      const state = context.state;
      if (isNil(state.selectedShoppingCartItems)) {
        state.selectedShoppingCartItems = [];
        return;
      }

      if (isEmpty(state.quoteResponse) || isEmpty(state.quoteResponse.offers)) {
        return;
      }

      const roomRequestIds = context.getters.roomRequests.map((it) => it.id);
      state.selectedShoppingCartItems.forEach((shoppingCart) => {
        if (!roomRequestIds.includes(shoppingCart.roomRequestId)) {
          // unselect any stale shopping cart items
          const idxToDelete = findIndex(state.roomRequests, (itInner) => shoppingCart.roomRequestId === itInner.roomRequestId);
          if (idxToDelete >= 0) {
            state.roomRequests.splice(idxToDelete, 1);
          }
        } else if (!isNil(shoppingCart.shoppingCartItems)) {
          const selectedOfferIdentifier = find(context.getters.selectedOffers, (it) => it.roomRequestId === shoppingCart.roomRequestId);

          if (!isNil(selectedOfferIdentifier)) {
            const offer = find(
              state.quoteResponse.offers,
              (it) => it.ratePlanId === selectedOfferIdentifier.ratePlanId
                && it.roomTypeId === selectedOfferIdentifier.roomTypeId
                && it.roomRequestId === selectedOfferIdentifier.roomRequestId,
            );

            shoppingCart.shoppingCartItems.forEach((shoppingCartItem) => {
              const shoppingCartItemOffer = isNil(offer.bookableShoppingCartItems)
                ? null
                : find(offer.bookableShoppingCartItems, (it) => it.shoppingCartItem.inHouseServiceId === shoppingCartItem.inHouseServiceId);

              if (isNil(shoppingCartItemOffer)) {
                shoppingCartItem.count = 0;
              } else {
                shoppingCartItem.count = Math.min(Math.max(shoppingCartItemOffer.minCount, shoppingCartItem.count), shoppingCartItemOffer.maxCount);
              }

              if (shoppingCartItem.count === 0) {
                shoppingCartItem.selected = false;
              }
            });
          }
        }
      });
    },
    removeUnavailableRequests(context) {
      if (isNil(context.getters.roomTypeAvailability)) return;
      const newVal = context.getters.enhancedRoomRequests; // enhancedRoomRequests might not have been recomputed at this time
      if (!isEmpty(newVal)) { // && size(newVal) > 1) {
        const roomRequestWithNoOccupancy = last(newVal.filter((it) => it.maxOccupancy === -1));
        if (!isEmpty(roomRequestWithNoOccupancy)) {
          context.commit('removeRoomRequest', roomRequestWithNoOccupancy);
          // context.dispatch('removeUnavailableRequests');
        }
      }
    },
    removeStaleOffers(context) {
      const roomRequestIds = context.getters.roomRequests.map((it) => it.id);
      context.getters.selectedOffers.forEach((it) => {
        if (!roomRequestIds.includes(it.roomRequestId)) {
          context.dispatch('unselectOffer', it.roomRequestId);
        }
      });

      context.getters.selectedShoppingCartItems.forEach((it) => {
        if (!roomRequestIds.includes(it.roomRequestId)) {
          context.dispatch('unselectOffer', it.roomRequestId);
        }
      });
    },
    unselectUnavailableOffers(context) {
      const selectedOfferMap = keyBy(context.getters.selectedOffers, 'roomRequestId');
      context.getters.roomRequests.forEach((roomRequest) => {
        // test;
        const selectedOffer = selectedOfferMap[roomRequest.id];
        const quoteResponse = context.getters.quoteResponse;
        if (!isEmpty(selectedOffer) && !isEmpty(quoteResponse)) {
          if (isEmpty(quoteResponse.offers)) {
            context.commit('unselectOffer', roomRequest.id);
          } else {
            const quoteOffer = head(quoteResponse.offers.filter((offer) => offer.ratePlanId === selectedOffer.ratePlanId
                && offer.roomTypeId === selectedOffer.roomTypeId
                && offer.roomRequestId === selectedOffer.roomRequestId));

            if (isNil(quoteOffer)) {
              context.commit('unselectOffer', roomRequest.id);
            }
          }
        }
      });
    },
    createInitialRoomRequest(context) {
      if (isEmpty(context.getters.roomRequests)) {
        const roomTypeMap = keyBy(context.state.hotel.roomTypes, 'id');
        const roomTypeAvailability = context.getters.roomTypeAvailability;

        if (!isNil(roomTypeAvailability)) {
          const roomTypeAvailabilityWithOccupancy = Object.values(roomTypeAvailability)
            .filter((it) => !isNil(roomTypeMap[it.roomTypeId]))
            .map((it) => ({
              roomTypeId: it.roomTypeId,
              minOccupancy: roomTypeMap[it.roomTypeId].minOccupancy,
              expectedOccupancy: roomTypeMap[it.roomTypeId].expectedOccupancy,
              maxOccupancy: roomTypeMap[it.roomTypeId].maxOccupancy,
              available: it.countAvailable,
            }));

          const roomsAvailable = sumBy(Object.values(roomTypeAvailability), 'countAvailable');

          if (roomsAvailable > 0) {
            const minOccupancy = minBy(roomTypeAvailabilityWithOccupancy, 'minOccupancy').minOccupancy;
            const maxOccupancy = maxBy(roomTypeAvailabilityWithOccupancy, 'maxOccupancy').maxOccupancy;
            let occupancy = minOccupancy || 1;
            if (occupancy === 1 && maxOccupancy >= 2) occupancy = 2;
            context.dispatch('addRoomRequest', {
              id: shortid.generate(),
              numAdults: occupancy,
              childAgeCategories: [],
            });
          }
        }
      }
    },
    update123CompareMe(context) {
      try {
        const state = context.state;

        window.pricecomparison = {};
        window.pricecomparison.hotelId = state.hotelId;
        window.pricecomparison.language = state.language || state.hotel.details.primaryLanguage || 'de';

        if (!isEmpty(state.quoteRequest)) {
          const ageCategoryMap = keyBy(state.hotel.ageCategories, 'id');

          window.pricecomparison.dateIn = fecha.format(fecha.parse(state.quoteRequest.arrivalDate, 'YYYYMMDD'), 'DD/MM/YYYY');
          window.pricecomparison.dateOut = fecha.format(fecha.parse(state.quoteRequest.departureDate, 'YYYYMMDD'), 'DD/MM/YYYY');
          window.pricecomparison.rooms = state.quoteRequest.roomRequests
            .map((it) => ({
              adults: it.numAdults,
              children: {
                qty: size(it.childAgeCategories),
                ages: it.childAgeCategories.map((ac) => Math.ceil((ageCategoryMap[ac].ageFrom + ageCategoryMap[ac].ageUntil) / 2)),
              },
            }));
        }

        if (!isEmpty(state.quoteResponse) && !isEmpty(state.quoteResponse.offers)) {
          const cheapestOffer = minBy(state.quoteResponse.offers, (offer) => offer.unitPriceSummary.amount + offer.shoppingCartSummary.amount);

          if (!isEmpty(cheapestOffer)) {
            window.pricecomparison.price = (cheapestOffer.unitPriceSummary.amount + cheapestOffer.shoppingCartSummary.amount) / cheapestOffer.rates.length;
            window.pricecomparison.currency = state.hotel.details.currency;

            const cheapestRoomType = keyBy(state.hotel.roomTypes, 'id')[cheapestOffer.roomTypeId];
            window.pricecomparison.roomInformation = cheapestRoomType.name[state.language] || cheapestRoomType.name[state.hotel.details.primaryLanguage];
          }
        }
      } catch (ignore) { /* ignore */ }
    },
    updateUserSelections(context) {
      // updating data first
      context.dispatch('enhancedRoomRequests');
      context.dispatch('availabilityByMaxOccupancy');
      // data cleanup afterwards - in case any of them is dependent on recomputed data
      context.dispatch('removeUnavailableRequests');
      context.dispatch('removeStaleOffers');
      context.dispatch('unselectUnavailableOffers');
      context.dispatch('createInitialRoomRequest');
      context.dispatch('updateShoppingCartCounts');
    },
    availabilityByMaxOccupancy(context) {
      const state = context.state;
      if (isNil(state.roomTypeAvailability)) {
        context.state.availabilityByMaxOccupancy = null;
        return;
      }
      if (isNil(state.hotel)) {
        context.state.availabilityByMaxOccupancy = null;
        return;
      }
      if (isNil(state.hotel.roomTypes)) {
        context.state.availabilityByMaxOccupancy = null;
        return;
      }

      const roomTypeMap = keyBy(state.hotel.roomTypes, 'id');

      context.state.availabilityByMaxOccupancy = keyBy(
        map(
          groupBy(
            Object.values(state.roomTypeAvailability)
              .filter((it) => !isNil(roomTypeMap[it.roomTypeId]))
              .map((it) => ({
                ...it,
                maxOccupancy: parseInt(roomTypeMap[it.roomTypeId].maxOccupancy, 10),
              })),
            'maxOccupancy',
          ),
          (obj, maxOccupancy) => ({
            maxOccupancy: parseInt(maxOccupancy, 10),
            countAvailable: sumBy(obj, 'countAvailable'),
          }),
        ),
        'maxOccupancy',
      );
    },
    enhancedRoomRequests(context) {
      const state = context.state;
      const getters = context.getters;

      if (isNil(getters.availabilityByMaxOccupancy)) return;

      const occupancySteps = Object.keys(getters.availabilityByMaxOccupancy).map((it) => parseInt(it, 10));
      occupancySteps.sort();

      const getMaxOccupancyStep = (roomRequest) => {
        const numPersons = roomRequest.numAdults + size(roomRequest.childAgeCategories);

        return head(occupancySteps.filter((it) => numPersons <= it)) || last(occupancySteps);
      };

      const getMaxOccupancy = (applicableRoomRequests) => {
        const availabilityByMaxOccupancy = cloneDeep(getters.availabilityByMaxOccupancy);
        applicableRoomRequests.forEach((it) => {
          let step = it.maxOccupancyStep;
          while (!isNil(step)) {
            if (availabilityByMaxOccupancy[step].countAvailable > 0) {
              availabilityByMaxOccupancy[step].countAvailable -= 1;
              break;
            } else {
              const remainingOccupancySteps = takeRight(occupancySteps, (occupancySteps.length - occupancySteps.indexOf(step) - 1));
              step = head(remainingOccupancySteps);
            }
          }
        });

        const step = maxBy(Object.values(availabilityByMaxOccupancy).filter((it) => it.countAvailable > 0), 'maxOccupancy');

        return isNil(step) ? -1 : parseInt(step.maxOccupancy, 10);
      };

      const roomRequests = getters.roomRequests.map((it) => ({
        ...it,
        maxOccupancyStep: getMaxOccupancyStep(it),
      }));

      context.state.enhancedRoomRequests = roomRequests.map((it) => ({
        ...it,
        maxOccupancy: getMaxOccupancy(roomRequests.filter((inner) => inner.id !== it.id)),
      }));
    },
  },
  modules: {
  },
});
