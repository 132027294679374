// const baseApiAddress = 'https://api.hotelmeister.io/rest/ui/backend/';

const baseApiAddresses = {
  development: 'http://localhost:8080/rest',
  staging: 'https://api.simply-hotel.at/rest',
  production: 'https://api.hotelsoftware.io/rest',
};

// export default baseApiAddresses[process.env.HOTELMEISTER_ENV];
export default baseApiAddresses[process.env.NODE_ENV];
