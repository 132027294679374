// define a mixin object
import { isEmpty, isNil } from 'lodash/lang';
import fecha from 'fecha';
import { head } from 'lodash/array';

export default {

  computed: {
    locale: function () {
      return (this.$i18n.locale || 'de').substr(0, 2).toLowerCase();
    },
    arrivalDateDate: function () {
      return isNil(this.$store.getters.arrivalDate) ? null : fecha.parse(this.$store.getters.arrivalDate, 'YYYYMMDD');
    },
    departureDateDate: function () {
      return isNil(this.$store.getters.departureDate) ? null : fecha.parse(this.$store.getters.departureDate, 'YYYYMMDD');
    },
    formattedDateRange: function () {
      if (isNil(this.departureDateDate) || isNil(this.arrivalDateDate)) return null;

      const format = {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      };

      const dtf = new Intl.DateTimeFormat(this.locale, format);

      let delimiter = ' - ';

      if (dtf.formatToParts) {
        const parts = dtf.formatToParts(this.arrivalDateDate);

        delimiter = head(parts.filter((it) => it.type === 'literal' && it.source === 'shared' && it.value.trim() !== ''));
      }

      return `${this.formattedArrivalDate}${isEmpty(delimiter) ? ' – ' : delimiter}${this.formattedDepartureDate}`;
    },
    formattedArrivalDate: function () {
      if (isNil(this.arrivalDateDate)) return null;

      const format = {
        day: 'numeric',
        month: 'long',
      };

      if (isNil(this.departureDateDate) || this.arrivalDateDate.getFullYear() !== this.departureDateDate.getFullYear()) format.year = 'numeric';

      return new Intl.DateTimeFormat(this.locale, format).format(this.arrivalDateDate);
    },
    formattedDepartureDate: function () {
      if (isNil(this.departureDateDate) || isNil(this.arrivalDateDate)) return null;

      const format = {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      };

      return new Intl.DateTimeFormat(this.locale, format).format(this.departureDateDate);
    },
    numberNights() {
      return Math.round((this.departureDateDate - this.arrivalDateDate) / (1000 * 60 * 60 * 24));
    },
  },
};
