<template>
  <transition
    enter-active-class="transition ease-out duration-100 transform"
    enter-class="opacity-0 scale-95"
    enter-to-class="opacity-100 scale-100"
    leave-active-class="transition ease-in duration-75 transform"
    leave-class="opacity-100 scale-100"
    leave-to-class="opacity-0 scale-95"
  >
    <!-- TODO: vue transition adds position relative instead of fixed -->
    <div v-show="isOpen" class="info-box fixed top-16 left-16 bg-dark rounded-4 z-90 w-full text-white pl-16 pr-40 py-12 text-body-small">
      {{ msg }}
      <button class="absolute right-0 top-0 p-12" :aria-label="$t('infoBox.ariaLabelClose')" @click="hide">
        <x-icon class="text-white" size="16"></x-icon>
      </button>
    </div>
  </transition>
</template>

<script>

import { XIcon } from 'vue-feather-icons';
import TimerMixin from '@/mixins/TimerMixin';

export default {
  name: 'InfoBox',
  components: { XIcon },

  mixins: [TimerMixin],

  props: {
  },

  data: function () {
    return {
      hidden: true,
      msg: null,
      timer: null,
    };
  },

  computed: {
    isOpen() {
      return !this.hidden;
    },
  },

  watch: {

  },

  created: function () {
    this.$serviceBus.$on('infoBox.msg', this.showMessage);
    this.$serviceBus.$on('infoBox.hide', this.hide);
  },

  mounted: function () {
    this.mounted = true;
  },

  methods: {
    showMessage(msg) {
      this.msg = msg;
      this.hidden = false;

      this.clearTimeout(this.timer);
      this.timer = null;

      this.timer = this.setTimeout(this.hide, 10000);
    },
    hide() {
      if (this.timer) {
        this.clearTimeout(this.timer);
        this.timer = null;
      }
      this.hidden = true;
    },
  },
};
</script>

<style lang="scss">
  @import '~@/styles/import';

  .info-box {
    max-width: calc(100% - 3.2rem);
  }

</style>
