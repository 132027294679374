<template>
  <column1>
    <h1 class="h1 mb-32">
      Disabled
    </h1>
    <p class="mb-8">
      <!-- de -->
      Dieser Betrieb hat seine Online-Buchung vorübergehend deaktiviert.
    </p>
    <hr />
    <p class="mt-8 mb-8">
      <!-- en -->
      This company has temporarily deactivated its online booking.
    </p>
    <hr />
    <p class="mt-8 mb-8">
      <!-- nl -->
      Dit bedrijf heeft zijn online boeking tijdelijk gedeactiveerd.
    </p>
    <hr />
    <p class="mt-8 mb-8">
      <!-- es -->
      Esta empresa ha desactivado temporalmente su reserva online.
    </p>
    <hr />
    <p class="mt-8 mb-8">
      <!-- it -->
      Questa società ha temporaneamente disattivato la prenotazione online.
    </p>
    <hr />
    <p class="mt-8 mb-8">
      <!-- fr -->
      Cette entreprise a temporairement désactivé sa réservation en ligne.
    </p>
    <hr />
    <p class="mt-8 mb-8">
      <!-- ru -->
      Эта компания временно деактивировала онлайн-бронирование.
    </p>
    <hr />
    <p class="mt-8 mb-8">
      <!-- zh -->
      该公司已暂时停用其在线预订。
    </p>
    <hr />
    <p class="mt-8 mb-8 text-rtl">
      <!-- he -->
      חברה זו ביטלה באופן זמני את ההזמנה המקוונת שלה.
    </p>
    <hr />
    <p class="mt-8 mb-8 text-rtl">
      <!-- ar -->
      قامت هذه الشركة بإلغاء تنشيط حجزها عبر الإنترنت مؤقتًا.
    </p>
  </column1>
</template>

<script>
import ButtonFloating from '@/components/lib/ButtonFloating/ButtonFloating';
import Column1 from '@/components/lib/Layout/Column1';
import { isEmpty } from 'lodash/lang';

export default {
  name: 'ErrorView',
  components: {
    'button-floating': ButtonFloating,
    Column1,
  },

  data: function () {
    return {
      lastHotelId: null,
    };
  },

  created: function () {
    try {
      this.lastHotelId = localStorage.getItem('lastHotelId');
    } catch (_) { /* ignore */ }
  },

  methods: {
    isEmpty(val) {
      return isEmpty(val);
    },
    navigateToHotel() {
      this.$router.push({ name: 'home', params: { encodedHotelId: this.lastHotelId } });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '~@/styles/import';

hr {
  border-color: $grayBlue!important;
}

.text-rtl {
  text-align: right;
  direction: rtl;
}
</style>
