<template>
  <div class="md:fixed left-0 top-0 md:w-400 bg-white md:h-screen overflow-y-auto nav">
    <nav class="relative grid md:block justify-between items-center h-64 md:min-h-screen px-16 md:px-48 md:pt-128 md:pb-128 md:h-auto">
      <button class="p-8 md:hidden" :class="[{'pointer-events-none opacity-0': isNil(previousStep)}]" @click="onClickBack">
        <chevron-left-icon class="text-primary"></chevron-left-icon>
      </button>
      <div v-if="!isNil(hotel.details) && !isEmpty(hotel.details.logo)" class="logo md:mb-48 hidden md:block">
        <img :src="hotel.details.logo" :alt="$t('mainNav.logoAltText')" class="logo">
      </div>
      <div class="md:mb-48 truncate" :class="[{'block md:hidden': !isNil(hotel.details) && !isEmpty(hotel.details.logo)}]">
        <span class="text-18 md:text-24 text-black font-bold uppercase whitespace-no-wrap md:whitespace-normal">{{ hotel.hotel.hotelName }}</span>
      </div>
      <button v-if="mobileMenu" class="p-8 md:hidden" @click="toggleMobileMenu">
        <x-icon class="text-primary"></x-icon>
      </button>
      <div v-else class="dropdown md:hidden" @click="toggleMobileMenu">
        {{ step }}/6
      </div>
      <div ref="nav" class="list nav-list-wrapper md:-mx-48 md:px-48" :class="[{ 'block': mobileMenu }]">
        <ul class="nav-list">
          <li>
            <fade-transition group>
              <nav-item key="home"
                        :to="{ name: 'home' }"
                        :exact="true"
                        :complete="!isNil(arrivalDateDate) && !isNil(departureDateDate)"
                        :title="$t('mainNav.date')"
              >
                <template v-slot:complete>
                  {{ formattedDateRange }}
                </template>
                <template v-slot:default>
                  <template v-if="isNil(arrivalDateDate)">
                    {{ $t('homeView.selectArrivalDate') }}
                  </template>
                  <template v-else-if="isNil(departureDateDate)">
                    {{ $t('homeView.selectDepartureDate') }}
                  </template>
                </template>
              </nav-item>
              <nav-item key="roomcount"
                        :to="{ name: 'roomcount' }"
                        :exact="true"
                        :disabled="isNil(arrivalDateDate) || isNil(departureDateDate)"
                        :complete="$store.getters.roomRequests.length > 0"
                        :title="$t('mainNav.guests')"
              >
                <template v-slot:complete>
                  <i18n path="roomSelect.roomRequestDescription" tag="span" class="text-gray text-selected text-12 mt-8">
                    <template v-slot:adults>
                      {{ $tc('roomSelect.roomRequestDescription1', numAdults, [numAdults]) }}
                    </template>
                    <template v-if="numChildren > 0" v-slot:children>
                      {{ $tc('roomSelect.roomRequestDescription2', numChildren, [numChildren]) }}
                    </template>
                  </i18n>
                  <template v-if="!isEmpty(discountCode)">
                    <br />{{ $t('roomCountView.discountCode') }}: {{ discountCode }}
                  </template>
                </template>
                <template v-slot:default>
                  {{ $t('mainNav.guestsDescription') }}
                </template>
              </nav-item>
              <nav-item v-if="isEmpty(roomRequests)"
                        key="roomselect"
                        :to="{ name: 'roomselect', params: { roomRequestId: '-' } }"
                        :disabled="isEmpty(roomRequests)"
                        :exact="false"
                        :title="$tc('mainNav.room', 0)"
              >
                <template v-slot:default>
                  {{ $t('mainNav.roomDescription') }}
                </template>
              </nav-item>
              <template v-else>
                <nav-item v-for="(roomRequest, idx) in roomRequests"
                          :key="roomRequest.id"
                          :to="{ name: 'roomselect', params: { roomRequestId: roomRequest.id } }"
                          :complete="!isEmpty(roomTypeByRoomRequestId[roomRequest.id])"
                          :disabled="isEmpty(roomRequests)"
                          :exact="false"
                          active-class="active"
                          :title="roomRequests.length === 1 ? $tc('mainNav.room', 1) : $t('mainNav.nthRoom', [idx + 1])"
                >
                  <template v-slot:default>
                    {{ $t('mainNav.roomDescription') }}
                  </template>
                  <template v-slot:complete>
                    {{ roomTypeByRoomRequestId[roomRequest.id].name[locale] }}
                    <template v-if="!isEmpty(getOffer(roomRequest.id)) && hotel.hotel.whiteLabel === 'Rainmaker'">
                      ({{ hotel.details.currency }}&nbsp;{{ (getOffer(roomRequest.id).unitPriceSummary.amount + getOffer(roomRequest.id).unitPriceSummary.excludedTaxAmount + shoppingCartSummaryByRoomRequest[roomRequest.id].amount + shoppingCartSummaryByRoomRequest[roomRequest.id].excludedTaxAmount) | toCurrency }})
                    </template>
                  </template>
                </nav-item>
              </template>
              <nav-item key="contact"
                        :to="{ name: 'contact' }"
                        :complete="!isEmpty(contactData) && !isEmpty(contactData.lastName)"
                        :exact="true"
                        :title="$t('mainNav.contact')"
              >
                <template v-slot:default>
                  {{ $t('mainNav.contactDescription') }}
                </template>
                <template v-slot:complete>
                  {{ displayName }}<template v-if="!isEmpty(companyName)">
                    / {{ companyName }}
                  </template>
                </template>
              </nav-item>
              <nav-item key="summary"
                        :to="{ name: 'summary' }"
                        :disabled="isEmpty(contactData) || contactData.complete !== true"
                        :exact="true"
                        :title="$t('mainNav.summary')"
              />
            </fade-transition>
          </li>
        </ul>

        <div class="nav-footer-links">
          <a :href="hotel.hotel.whiteLabelUrl" target="_blank" rel="noopener" class="col-span-1">{{ $t('poweredBy') }} <span class="text-black font-bold">{{ hotel.hotel.whiteLabelName }}</span></a>
          <div class="nav-footer-links-legal">
            <a href="#" class="font-bold" @click="showImprint">{{ $t('column1.imprint') }}</a>
            <span class="font-bold">·</span>
            <a href="#" class="font-bold" @click="showPrivacy">{{ $t('column1.privacy') }}</a>
            <template v-if="consent != null">
              <span class="font-bold">·</span>
              <a href="#" class="font-bold" @click="showCookieconsent">{{ $t('column1.cookie', 'Cookies') }}</a>
            </template>
          </div>
          <div class="nav-footer-links-legal">
            <select v-model="$i18n.locale" type="select" class="language-switcher col-span-1">
              <option value="de">
                Deutsch
              </option>
              <option value="en">
                English
              </option>
              <option value="es">
                Español
              </option>
              <option value="fr">
                Français
              </option>
              <option value="it">
                Italiano
              </option>
              <option value="nl">
                Nederlands
              </option>
              <option value="pl">
                Polski
              </option>
              <option value="pt">
                Português
              </option>
              <option value="ru">
                Русский язык
              </option>
              <option value="cs">
                čeština
              </option>
              <option value="el">
                ελληνικά
              </option>
              <option value="fi">
                suomi
              </option>
              <option value="hu">
                magyar
              </option>
              <option value="ja">
                日本語
              </option>
              <option value="ro">
                limba română
              </option>
              <option value="sk">
                slovenčina
              </option>
              <option value="sl">
                slovenščina
              </option>
              <option value="sv">
                Svenska
              </option>
              <option value="zh">
                中文
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="absolute bottom-0 left-0 h-2 bg-primary w-step/6 md:hidden" :style="`--step: ${step};`"></div>
    </nav>
  </div>
</template>

<script>

import ResizeSensor from 'vue-resize-sensor';
import { ChevronLeftIcon, CheckIcon, XIcon } from 'vue-feather-icons';
import BookingEngineMixin from '@/mixins/BookingEngineMixin';
import { isEmpty, isEqual, isNil } from 'lodash/lang';
import fecha from 'fecha';
import { findIndex, head, reverse, slice, uniq } from 'lodash/array';
import { FadeTransition } from 'vue2-transitions';
import { find, groupBy, keyBy, map } from 'lodash/collection';
import DateRangeMixin from '@/mixins/DateRangeMixin';
import { sumBy } from 'lodash/math';
import NavItem from '@/components/lib/Nav/NavItem';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import CookieMixin from '@/mixins/CookieMixin';
import TimerMixin from '@/mixins/TimerMixin';

export default {
  name: 'Nav',
  components: { ChevronLeftIcon, CheckIcon, XIcon, FadeTransition, NavItem },

  mixins: [BookingEngineMixin, DateRangeMixin, CookieMixin, TimerMixin],

  props: {

  },

  data: function () {
    return {
      mobileMenu: false,
    };
  },

  computed: {
    roomTypeByRoomRequestId() {
      if (isNil(this.selectedOffers)) return {};
      const ret = groupBy(this.selectedOffers, 'roomRequestId');
      Object.keys(ret).forEach((roomRequestId) => {
        ret[roomRequestId] = isEmpty(ret[roomRequestId]) ? null : this.roomTypeMap[ret[roomRequestId][0].roomTypeId];
      });

      return ret;
    },
    selectedOffers() {
      return this.$store.getters.selectedOffers;
    },
    roomTypeMap() {
      return keyBy(this.hotel.roomTypes, 'id');
    },
    previousStep() {
      const currentStep = this.currentStep;
      if (currentStep > 0) {
        const stepsBefore = slice(this.navigationSteps, 0, currentStep);
        const previousStep = find(reverse(stepsBefore), (it) => this.navigableBackSteps.includes(it.name));
        return previousStep;
      }

      return null;
    },
    currentStep() {
      const step = {
        name: this.$route.name,
      };

      if (!isEmpty(this.$route.params)) {
        step.params = {
          ...this.$route.params,
        };
      }

      return findIndex(this.navigationSteps, (it) => isEqual(it, step));
    },
    navigableBackSteps() {
      return ['home', 'roomcount', 'roomselect', 'contact'];
    },
    step() {
      switch (this.$route.name) {
        case 'home': return 1;
        case 'roomcount': return 2;
        case 'contact': return 4;
        case 'summary': return 5;
        case 'confirmation': return 6;
        default:
          return 3;
      }
    },
    navigationSteps() {
      const defaultParams = { params: { encodedHotelId: this.$route.params.encodedHotelId } };
      return [
        { name: 'home', ...defaultParams },
        { name: 'roomcount', ...defaultParams },
        ...this.roomSelectNavigationSteps,
        { name: 'contact', ...defaultParams },
        { name: 'summary', ...defaultParams },
      ];
    },
    roomSelectNavigationSteps() {
      if (isEmpty(this.roomRequests)) return [{ name: 'roomselect', params: { roomRequestId: '-' } }];
      return this.roomRequests.flatMap((roomRequest) => [
        {
          name: 'roomselect',
          params: { roomRequestId: roomRequest.id, encodedHotelId: this.$route.params.encodedHotelId },
        },
        ...(this.hotel.roomTypes.map((roomType) => ({ name: 'rateselect', params: { roomRequestId: roomRequest.id, roomTypeId: roomType.id } }))),
      ]);
    },
    numAdults() {
      return sumBy(this.$store.getters.roomRequests, 'numAdults');
    },
    numChildren() {
      return sumBy(this.$store.getters.roomRequests, (it) => it.childAgeCategories.length);
    },
    contactData() {
      return this.$store.getters.contactData;
    },
    displayName() {
      return `${this.contactData.firstName || ''} ${this.contactData.lastName || ''}`.trim();
    },
    companyName() {
      return this.contactData.alternateBillingAddress ? `${this.contactData.companyName || ''}`.trim() : '';
    },
  },

  watch: {

  },

  created: function () {
    this.$serviceBus.$on('back', this.navigateBack);
    this.$serviceBus.$on('navigation.click', this.navigateClick);

    window.addEventListener('resize', this.hideNavigation);
  },

  beforeDestroy() {
    this.$serviceBus.$off('back', this.navigateBack);
    this.$serviceBus.$off('navigation.click', this.navigateClick);

    window.removeEventListener('resize', this.hideNavigation);
  },

  mounted: function () {
    this.mounted = true;
  },

  methods: {
    navigateBack() {
      const previousStep = this.previousStep;
      if (!isNil(previousStep)) {
        this.$router.push(previousStep);
      }
    },
    onClickBack() {
      this.$serviceBus.$emit('back');
      this.$serviceBus.$emit('navigation.click');
    },
    toggleMobileMenu() {
      this.mobileMenu = !this.mobileMenu;

      if (this.mobileMenu) {
        disableBodyScroll(this.$refs.nav);
      } else {
        enableBodyScroll(this.$refs.nav);
      }
    },
    navigateClick() {
      this.hideNavigation();
    },
    hideNavigation() {
      if (this.mobileMenu) {
        this.toggleMobileMenu();
      }
    },
  },
};
</script>

<style lang="scss">
  @import '~@/styles/import';

  .logo {
    max-height: 8.5rem;
    height: auto;
    width: auto;
    max-width: 100%;
  }

  nav {
    grid-template-columns: min-content auto min-content;
    grid-column-gap: 1rem;
  }

  .nav-list {
    li {
      a {
        @apply relative -ml-24 pl-24 justify-center py-16;
        //@apply flex flex-col;
        @apply grid;
        grid-template-columns: 1fr min-content;
        grid-auto-flow: row;
        grid-auto-rows: min-content;

        @media (min-width: 768px) {
          @apply -ml-48 pl-48;
        }

        span {
          @apply hidden;
        }

        &[disabled] {
          @apply pointer-events-none;
        }

        h3 {
          @apply text-16 text-gray font-semibold;
        }

        .check {
          opacity: 0;
        }

        &.complete {
          h3 {
            @apply text-primary;
          }

          .check {
            opacity: 1;
          }

          span {
            @apply block;
          }
        }

        &.active {
          h3 {
            @apply text-16 text-dark font-semibold;
          }

          @apply pl-36;

          @media (min-width: 768px) {
            @apply pl-56;
          }

          .text-selected {
            @apply text-dark;
          }

          span {
            @apply block;
            &:not(.text-gray) {
              @apply text-dark;
            }
          }

          &:before {
            content: '';
            @apply w-4 h-full absolute left-0 top-0 bg-primary rounded-2;
          }
        }
      }
    }
  }

  .nav-footer-links {
    @apply grid bottom-0 left-24 right-24 pt-24 mt-24 -mx-24 px-24 overflow-hidden text-12 text-gray border-t-1 border-grayLight;

    @media (min-width: 768px) {
      @apply absolute pb-24;
    }

    line-height: 1.6rem;

    grid-auto-rows: 1fr;
    grid-template-columns: 1fr;
    grid-row-gap: 0.8rem;

    &-legal {
      @apply grid;
      grid-column-gap: 0.5rem;
      grid-template-columns: repeat(5, min-content);
    }

    a {
      @apply whitespace-no-wrap;
      &:hover {
        @apply underline;
      }
    }
  }

  .language-switcher {
    @apply relative -left-4 -top-1 bg-transparent border-0 text-12 px-0 text-gray cursor-pointer font-bold;

    width: 100%;
    width: min-content;
  }

  .nav-list-wrapper {
    @apply hidden;

    grid-template-rows: 1fr max-content;

    @apply overflow-auto;

    @media (max-width: 767px) {
      &.block {
        @apply top-64 fixed bg-white bottom-0 left-0 right-0 z-80 px-24 pb-24 pt-8 grid;
      }
    }

    @media (min-width: 768px) {
      @apply grid;
    }
  }
</style>
