<template>
  <div class="md:fixed left-0 top-0 md:w-400 bg-white nav">
    <nav class="relative grid md:block justify-between items-center h-64 md:h-screen px-16 md:px-48 md:pt-128 overflow-y-auto">
      <div v-if="!isNil(hotel.details) && !isEmpty(hotel.details.logo)" class="logo md:mb-48 hidden md:block">
        <img :src="hotel.details.logo" alt="Logo" class="logo">
      </div>
      <div class="md:mb-48 truncate" :class="[{'block md:hidden': !isNil(hotel.details) && !isEmpty(hotel.details.logo)}]">
        <span class="text-18 md:text-24 text-black font-bold uppercase whitespace-no-wrap md:whitespace-normal">{{ hotel.hotel.hotelName }}</span>
      </div>
      <div class="dropdown md:hidden">
        {{ step }}/6
      </div>
      <div class="absolute bottom-0 left-0 h-2 bg-primary w-step/6 md:hidden" :style="`--step: ${step};`"></div>
    </nav>
  </div>
</template>

<script>

import ResizeSensor from 'vue-resize-sensor';
import { ChevronLeftIcon } from 'vue-feather-icons';
import BookingEngineMixin from '@/mixins/BookingEngineMixin';
import { isEmpty, isEqual, isNil } from 'lodash/lang';
import fecha from 'fecha';
import { findIndex, head, reverse, slice, uniq } from 'lodash/array';
import { FadeTransition } from 'vue2-transitions';
import { find, groupBy, keyBy, map } from 'lodash/collection';
import DateRangeMixin from '@/mixins/DateRangeMixin';
import { sumBy } from 'lodash/math';
import CookieMixin from '@/mixins/CookieMixin';
import TimerMixin from '@/mixins/TimerMixin';

export default {
  name: 'ConfirmationNav',
  components: { ChevronLeftIcon, FadeTransition },

  mixins: [BookingEngineMixin, CookieMixin, DateRangeMixin, TimerMixin],

  props: {

  },

  data: function () {
    return {};
  },

  computed: {
    step() {
      return 6;
    },
  },

  watch: {

  },

  created: function () {
  },

  beforeDestroy() {
  },

  mounted: function () {
  },

  methods: {
  },
};
</script>

<style lang="scss">
  @import '~@/styles/import';

  .logo {
    max-height: 8.5rem;
    height: auto;
    width: auto;
    max-width: 100%;
  }

  nav {
    grid-template-columns: min-content auto min-content;
    grid-column-gap: 1rem;
  }
</style>
