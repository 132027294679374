<template>
  <Transition name="fade">
    <div
      v-if="showing"
      class="modal fixed inset-0 w-full h-screen flex items-center justify-center bg-smoke-800 z-50"
      @click.self="closeIfShown"
    >
      <div class="relative w-5/6 h-5/6 bg-white shadow-lg rounded-lg flex">
        <button v-if="showClose" class="close-button fixed top-16 right-28 close rounded-full w-40 h-40 bg-white flex items-center justify-center z-90 shadow-md border border-grayLight" @click.prevent="close" @click="close">
          <x-icon size="1.5x" class="text-dark"></x-icon>
        </button>
        <div class="overflow-auto max-h-screen w-full">
          <slot />
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import { XIcon } from 'vue-feather-icons';

export default {
  name: 'Modal',

  components: {
    XIcon,
  },

  props: {
    showing: {
      required: true,
      type: Boolean,
    },
    showClose: {
      type: Boolean,
      default: true,
    },
    backgroundClose: {
      type: Boolean,
      default: true,
    },
  },

  watch: {
    showing(value) {
      if (value) {
        return document.querySelector('body')
          .classList
          .add('overflow-hidden');
      }
      return document.querySelector('body')
        .classList
        .remove('overflow-hidden');
    },
  },

  created() {
    this.$serviceBus.$on('shortcut.esc', this.close);
  },

  beforeDestroy() {
    this.$serviceBus.$off('shortcut.esc', this.close);
  },

  methods: {
    close() {
      document.querySelector('body')
        .classList
        .remove('overflow-hidden');
      this.$emit('close');
    },
    closeIfShown() {
      if (this.showClose && this.backgroundClose) {
        this.close();
      }
    },
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.6s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
