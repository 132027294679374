/* eslint-disable global-require */
import Vue from 'vue';
import App from './App.vue';
// import './registerServiceWorker';
import router from './router';
import store from './store';
import VTooltip from 'v-tooltip';
import VueHotkey from 'v-hotkey';
import Vuex from 'vuex';

import '@/assets/css/style.css';
import baseUrl from './lib/urls';
import prefix from 'superagent-prefix';
import Vuelidate from 'vuelidate';

import VueI18n, { BaseFormatter } from 'vue-i18n';
import { isEmpty, isNil } from 'lodash/lang';
import { replace } from 'lodash/string';
import Rollbar from 'vue-rollbar';

import VueHead from 'vue-head';

import VueGtag from 'vue-gtag';

Vue.use(VueHead);

const countries = require('i18n-iso-countries');

countries.registerLocale(require('i18n-iso-countries/langs/de.json'));
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
countries.registerLocale(require('i18n-iso-countries/langs/fr.json'));
countries.registerLocale(require('i18n-iso-countries/langs/it.json'));
countries.registerLocale(require('i18n-iso-countries/langs/es.json'));
countries.registerLocale(require('i18n-iso-countries/langs/ru.json'));
countries.registerLocale(require('i18n-iso-countries/langs/zh.json'));

Vue.use(Rollbar, {
  accessToken: '3e8c671b647b4da4b9e101fdc8ef252f',
  scrubFields: ['firstName', 'lastName', 'address', 'zipCode', 'city', 'companyname', 'companyFirstName', 'companyLastName', 'companyaddress', 'companycity', 'email', 'phone', 'phoneNumber', 'companyZipCode'],
  captureUncaught: true,
  autoInstrument: { network: true, log: true, dom: true, navigation: true, connectivity: true },
  maxTelemetryEvents: 50,
  scrubTelemetryInputs: true,
  captureUnhandledRejections: true,
  enabled: process.env.NODE_ENV === 'production',
  environment: process.env.NODE_ENV,
  captureIp: 'anonymize',
  hostBlackList: ['localhost'],
  payload: {
    context: window.location.hash,
    environment: process.env.NODE_ENV,
  },
  ignoredMessages: [
    'NavigationDuplicated',
    'Navigation cancelled from',
    'Avoided redundant navigation to',
    'Script error.',
    'Identifier \'id_customer\' has already been declared',
    'redeclaration of let launched_screen',
    'find variable pricecomparison',
  ],
});

Vue.config.errorHandler = (err, vm, info) => {
  try {
    const state = vm.$store.getters.logData;

    vm.$rollbar.captureEvent('manual', {
      category: 'Store',
      action: 'Current State',
      metadata: {
        state,
      },
    }, 'info');

    const hotelState = vm.$store.getters.hotel;

    vm.$rollbar.captureEvent('manual', {
      category: 'Store',
      action: 'Hotel State',
      metadata: {
        hotelState,
      },
    }, 'info');
  } catch (ignore) { /* ignore */ }
  vm.$rollbar.error(err);
  throw err; // rethrow
};

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: 'de', // set locale
  fallbackLocale: ['en', 'de'],
  silentTranslationWarn: true,
  messages: {
    de: {
      ...require('@/i18n/de_primary/ibe.json'),
    },
    en: {
      ...require('@/i18n/en/ibe.json'),
    },
    es: {
      ...require('@/i18n/es/ibe.json'),
    },
    fr: {
      ...require('@/i18n/fr/ibe.json'),
    },
    it: {
      ...require('@/i18n/it/ibe.json'),
    },
    nl: {
      ...require('@/i18n/nl/ibe.json'),
    },
    pl: {
      ...require('@/i18n/pl/ibe.json'),
    },
    pt: {
      ...require('@/i18n/pt/ibe.json'),
    },
    ru: {
      ...require('@/i18n/ru/ibe.json'),
    },
    cs: {
      ...require('@/i18n/cs/ibe.json'),
    },
    el: {
      ...require('@/i18n/el/ibe.json'),
    },
    fi: {
      ...require('@/i18n/fi/ibe.json'),
    },
    hu: {
      ...require('@/i18n/hu/ibe.json'),
    },
    ja: {
      ...require('@/i18n/ja/ibe.json'),
    },
    ro: {
      ...require('@/i18n/ro/ibe.json'),
    },
    sk: {
      ...require('@/i18n/sk/ibe.json'),
    },
    sl: {
      ...require('@/i18n/sl/ibe.json'),
    },
    sv: {
      ...require('@/i18n/sv/ibe.json'),
    },
    zh: {
      ...require('@/i18n/zh/ibe.json'),
    },
  },
  missing: (locale, key, vm, values) => {
    if (key.includes('UNIT_TYPE')) {
      const hotel = isNil(vm) ? null : vm.$root.$store.getters.hotel;
      let wordingType = 'Room';
      if (!isEmpty(hotel) && !isEmpty(hotel.details) && !isEmpty(hotel.details.onlineBookingSettings) && !isEmpty(hotel.details.onlineBookingSettings.wordingType)) {
        wordingType = hotel.details.onlineBookingSettings.wordingType;
      }
      key = replace(key, 'UNIT_TYPE', wordingType);

      return isNil(vm) ? key : vm.$t(key);
    }
    return key;
  },
});

window.i18n = i18n;

Vue.use(Vuelidate);

const superagent = require('superagent-use')(require('superagent'));

Vue.config.productionTip = false;

superagent.use(prefix(baseUrl));

const serviceBus = new Vue();

Object.defineProperties(Vue.prototype, {
  $countries: {
    get: function get() {
      return countries;
    },
  },
  $superagent: {
    get: function get() {
      return superagent;
    },
  },
  $serviceBus: {
    get: function get() {
      return serviceBus;
    },
  },
});

const tooltipOptions = {
  // Default tooltip placement relative to target element
  defaultPlacement: 'top',
  // Default CSS classes applied to the tooltip element
  defaultClass: 'vue-tooltip-theme',
  // Default CSS classes applied to the target element of the tooltip
  defaultTargetClass: 'has-tooltip',
  // Is the content HTML by default?
  defaultHtml: true,
  // Default HTML template of the tooltip element
  // It must include `tooltip-arrow` & `tooltip-inner` CSS classes (can be configured, see below)
  // Change if the classes conflict with other libraries (for example bootstrap)
  defaultTemplate:
    '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>',
  // Selector used to get the arrow element in the tooltip template
  defaultArrowSelector: '.tooltip-arrow, .tooltip__arrow',
  // Selector used to get the inner content element in the tooltip template
  defaultInnerSelector: '.tooltip-inner, .tooltip__inner',
  // Delay (ms)
  defaultDelay: 0,
  // Default events that trigger the tooltip
  defaultTrigger: 'hover focus click',
  // Default position offset (px)
  defaultOffset: 0,
  // Default container where the tooltip will be appended
  defaultContainer: 'body',
  defaultBoundariesElement: undefined,
  defaultPopperOptions: {},
  // Class added when content is loading
  defaultLoadingClass: 'tooltip-loading',
  // Displayed when tooltip content is loading
  defaultLoadingContent: '...',
  // Hide on mouseover tooltip
  autoHide: true,
  // Close tooltip on click on tooltip target?
  defaultHideOnTargetClick: true,
  // Auto destroy tooltip DOM nodes (ms)
  disposeTimeout: 5000,
  // Options for popover
  popover: {
    defaultPlacement: 'bottom',
    // Use the `popoverClass` prop for theming
    defaultClass: 'vue-popover-theme',
    // Base class (change if conflicts with other libraries)
    defaultBaseClass: 'tooltip popover',
    // Wrapper class (contains arrow and inner)
    defaultWrapperClass: 'wrapper',
    // Inner content class
    defaultInnerClass: 'tooltip-inner popover-inner',
    // Arrow class
    defaultArrowClass: 'tooltip-arrow popover-arrow',
    // Class added when popover is open
    defaultOpenClass: 'open',
    defaultDelay: 0,
    defaultTrigger: 'click',
    defaultOffset: 0,
    defaultContainer: 'body',
    defaultBoundariesElement: undefined,
    defaultPopperOptions: {},
    // Hides if clicked outside of popover
    defaultAutoHide: true,
    // Update popper on content resize
    defaultHandleResize: true,
  },
};

Vue.use(VTooltip, tooltipOptions);
Vue.use(VueHotkey, {
  ß: 219,
  '/': 191,
  n0: 48,
  n1: 49,
  n2: 50,
  n3: 51,
  n4: 52,
  n5: 53,
  n6: 54,
  n7: 55,
  n8: 56,
  n9: 57,
});

Vue.use(Vuex);

Vue.use(VueGtag, {
  bootstrap: false,
  appName: 'HotelreservationExpert',
}, router);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
